import { eLayoutType, RoutesService } from "@abp/ng.core";
import { APP_INITIALIZER } from '@angular/core';


export const TEKNOSOL_COMPONENT_ISLETME_BAKIM_ARIZA_TALEBI_REPORT_ROUTE_PROVIDER=[
{
     provide:APP_INITIALIZER,useFactory:configureRoutes,deps:[RoutesService],multi:true
}
];

function configureRoutes(routes:RoutesService){
     return()=>{
          routes.add([
               {path:'/ariza-talebi-report',
               iconClass: 'fas fa-file-alt',
               name: '::Menu:ArizaTalebisReport',
               layout: eLayoutType.application,
               requiredPolicy: 'Ibys.BakimArizaReport',
               parentName:'::Menu:IBYS:Reports',
               order:1
               }
          ])
     }
}