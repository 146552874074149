
<div class="card ">
  <div class="card-body">
    <div [formGroup]="form" class="mb-2" >
        <div class="form-group" *ngIf="isVisible">
          <h3 class="label">Relation Key</h3>
          <input type="text" class="form-control ml-2 mb-4" formControlName="relationKey" />
        </div>
        <div class="form-group" *ngIf="isVisible">
          <h3 class="label">Relation Type</h3>
          <input type="text" class="form-control ml-2 mb-4" formControlName="relationType" />
        </div>
        <div class="form-group" *ngIf="isVisible">
          <h3 class="label">Tags</h3>
          <textarea type="text" class="form-control ml-2 mb-4" formControlName="tags" *ngIf="isVisible"></textarea>
        </div>
         <div class="row">
         <div class="col-md-6"  formArrayName="appFmExtraDataDto" ngDefaultControl [(ngModel)]="filtersExtraDataForParamCode.tanim" [ngModelOptions]="{ standalone: true }" *ngFor="let t of ExtraDataforControl.controls let i = index;">
         
          <div  [formGroupName]="i" > 
            <!-- DDDDDDDDDDDDDDDDDEEEEEEEEEEEEEEEEEENNNNNNNNNNNNNNNNEEEEEEEEEEEEEEEEEEEEEEEEEEEE -->
           <div *ngIf="dataExtraDataForParamCode.totalCount>0;else other_content">
 
              <label >{{ '::Enum:DefinationDataTypes:' + dataExtraDataForParamCode?.items[i]?.appFmExtraDataInfo?.tanim  | abpLocalization }}: 
          </label>
              <input readonly formControlName='value' id="{{'value' + i}}" type="text" class="form-control " value="{{dataExtraDataForParamCode.items[i].appFmExtraData.value}}"
                 />
            </div>
            <ng-template #other_content >
              <div *ngIf="dataExtraDataForParamCode.totalCount===0;">
      
                <label >{{'::Enum:DefinationDataTypes:' + dataInfo?.items[i]?.tanim | abpLocalization }}: 
                </label> 
                    <input readonly formControlName='value' id="{{'value' + i}}" type="text" class="form-control " value="{{paramobj.values[i].value}}"/>
              </div>
            </ng-template>
          </div>
         </div>  
        </div>
       
      <div class="form">
         <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" class="form-control" class="form-check-input" name="{{ '::MissingFile'   | abpLocalization }}" formControlName="missingFile"  value="{{ '::MissingFile'   | abpLocalization }}" checked>
            {{ '::MissingFile'   | abpLocalization }}
          </label>
        </div>
       </div>
       <br>
        <div class="input-group mb-3">
          <input type="file" name="pFile" #filee class="form-control" style="border: 0px;" id="file"
            (change)="handleFileInput($event.target.files);" required />
          <div class="input-group-append">
            <button class="btn btn-secondary btn-md ng-star-inserted" *abpPermission="'ViewSistem.FmRelations.Create'"
              (click)="UploadFile();" [disabled]="!submitEnabled">
              {{'::UploadFile'|abpLocalization}}
            </button>
          </div>
        </div>
        <textarea class="form-control mb-3" #fileDescription placeholder="Açıklama giriniz..."></textarea> 
       
            <div class="row">
              <div class="col-md-6">
                <label  class="form-group">{{ '::ChooseGroup'| abpLocalization }}: </label>
                <select
                [ngModel]="selectedName" 
                (ngModelChange)="onSelectName($event)"     
                class="form-control"
                formControlName="appFmGroupID"
              >
                <option [ngValue]=""></option>
                <option *ngFor="let column of groupItem.items" [ngValue]="{id:column.id,name:column.name}">
                  <p >{{column.name}}</p>
                </option>
              </select>
              </div>
              <div class="col-md-6">
                <label class="form-group"  >{{ '::DysValtyWarningDate'   | abpLocalization }}: </label>
                <input 
                  type="number" 
                  class="form-control"
                  formControlName="validityDateWarningDay"
                  required>
              </div> 
            <div class="col-md-6">
              <label class="form-group">  {{ '::DysDocumentDate'   | abpLocalization }}: </label>
              <input 
                type="date" 
                formControlName="documentDate"
                class="form-control"
                required>
            </div> 
            <div class="col-md-6">
              <label class="form-group">{{ '::DysDocumentValtyDate'   | abpLocalization }}: </label>
              <input 
                type="date" 
                formControlName="documentValdtyDate"
                class="form-control"
                required>
            </div> 
           
         </div>
          <!-- <span>Value:{{form.value|json}}</span>  -->
        </div>
      <div class="input-group">
        <input type="search" class="form-control" [placeholder]="'AbpUi::PagerSearch' | abpLocalization"
          [(ngModel)]="list.filter" />
        <div class="input-group-append">
          <button [title]="'AbpUi::Search' | abpLocalization" (click)="getContentsOfDirectory()"
            class="btn  btn-primary">
            <i class="fas fa-search" aria-hidden="true"></i>
          </button>
        </div>
      </div>
      <!-- <div class="data-container"> -->
     
  </div>
<!-- </div> -->


<!-- datatable Tuncay [groupRowsBy]="appFmExtraData"-->

<ngx-datatable [rows]="data?.items" [count]="data?.totalCount"   [loadingIndicator]="list.isLoading$ | async"
[limit]="list.maxResultCount"
[offset]="list.page"
(page)="list.page = $event.offset"
[headerHeight]="50"
[footerHeight]="50"
rowHeight="auto"
[scrollbarH]="true"
[scrollbarV]="false"
class="material" > 
  <ngx-datatable-column [name]="'AbpUi::Actions' | abpLocalization" [maxWidth]="150" [width]="150" >
    <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
      <div ngbDropdown id="container"  container="body" class="d-inline-block ">
        <button class="btn btn-primary btn-sm dropdown-toggle " data-toggle="dropdown" aria-haspopup="true" 
          ngbDropdownToggle >
          <i class="fa fa-cog mr-1" aria-hidden="true"></i>
          {{ 'AbpUi::Actions' | abpLocalization }}
        </button>
        <div ngbDropdownMenu>
          <span *ngIf="teknosolFileUploadService.isDownloadFile" >
            <button ngbDropdownItem *abpPermission="'ViewSistem.FmRelations.Edit'" (click)="Download(row)" >
              <i class="fa fa-check" ></i>{{ '::Download' | abpLocalization }}
            </button>
          </span>
          <span *ngIf="teknosolFileUploadService.isDeleteFile" >
            <button ngbDropdownItem *abpPermission="'ViewSistem.FmRelations.Delete'" (click)="Delete(row)">
              <i class="fa fa-trash"></i>{{ 'AbpUi::Delete' | abpLocalization }}
            </button>
          </span>
          <span *ngIf="teknosolFileUploadService.isDeleteFile" >
            <button ngbDropdownItem *abpPermission="'ViewSistem.FmRelations.Delete'" (click)="ShowFile(row)">
              <i class="fa fa-desktop"></i>  {{ '::DysDocumentShow' | abpLocalization }}
            </button>
          </span>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>


  <ngx-datatable-column name="{{ '::FileDescription' | abpLocalization }}" prop="Description" >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.description }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{'::FileName'|abpLocalization}}" prop="Name">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.name }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ '::FileSize' | abpLocalization }}" prop="Size">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.size }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ '::FileCreator' | abpLocalization }}" prop="Creator">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.creator }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ '::FileCreationTime' | abpLocalization }}" prop="CreationTime">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.creationTime | date: 'MM/dd/yyyy' }}
    </ng-template>
  </ngx-datatable-column>
 
  <ngx-datatable-column  name="{{ '::AppFmGroupID'| abpLocalization }}" prop="appFmGroupID">
    <ng-template let-row="row" *ngFor="let group of groupItem.items" ngx-datatable-cell-template>
     <p *ngIf='row.appFmGroupID===group.Id'> {{ group.Name }}</p> 
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ '::FmDocumentDate'| abpLocalization }}" prop="DocumentDate">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.documentDate| date: 'MM/dd/yyyy' }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ '::DocumentValdtyDate'| abpLocalization }}" prop="DocumentValdtyDate">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.documentValdtyDate | date: 'MM/dd/yyyy'}}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="{{ '::ValidityDateWarningDay'| abpLocalization }}" prop="ValidityDateWarningDay">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.validityDateWarningDay }}
    </ng-template>
  </ngx-datatable-column>  
  <ngx-datatable-column name="{{ '::Yetkili'| abpLocalization }}" prop="yetkili">
    <ng-template let-row="row"  ngx-datatable-cell-template>
      <span *ngIf="row.yetkili==true">Evet</span>
      <span *ngIf="row.yetkili==false">Hayir</span> 
      <!-- {{ row.missingFile }} -->
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

</div>

