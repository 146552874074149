import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_LAB_ORDER_LOG_BOOKS_LAB_ORDER_LOG_BOOK_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/lab-order-log-books',
        iconClass: 'fas fa-file-alt',
        parentName:'::Menu:Laboratory',
        name: '::Menu:LabOrderLogBooks',
        layout: eLayoutType.application,
        requiredPolicy: 'Laboratory.LabOrderLogBooks',
      },
    ]);
  };
}
