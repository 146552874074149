import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';



export const ISLETME_BAKIM_STOK_BIRIMS_STOK_BIRIM_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/stok-birims',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:StokBirims',
        layout: eLayoutType.application,
        requiredPolicy: 'VeraIntegration.StokBirims',
        parentName:'::Menu:Entegrasyon'
      },
    ]);
  };
}
