import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_LAB_ORDER_SUB_ANALYSIS_RESULTS_LAB_ORDER_SUB_ANALYSIS_RESULT_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/lab-order-sub-analysis-results',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:LabOrderSubAnalysisResults',
        layout: eLayoutType.application,
        requiredPolicy: 'AbpView.LabOrderSubAnalysisResults',
      },
    ]);
  };
}
