import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_LAB_ORDER_SAMPLE_LOGS_LAB_ORDER_SAMPLE_LOG_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/lab-order-sample-logs',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:LabOrderSampleLogs',
        layout: eLayoutType.application,
        requiredPolicy: 'Laboratory.LabOrderSampleLogs',
        parentName:"::Menu:LogTabloları",
      },
    ]);
  };
}
