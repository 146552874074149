import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const REPORT_FILE_ROLE_AUTHS_REPORT_FILE_ROLE_AUTH_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/report-file-role-auths',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:ReportFileRoleAuths',
        layout: eLayoutType.application,
        requiredPolicy: 'AbpView.ReportFileRoleAuths',
      },
    ]);
  };
}
