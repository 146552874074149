import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const VERA_RECEIPT_ADVICE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/vera-receipt-advice',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:VeraReceiptAdvice',
        layout: eLayoutType.application,
        parentName:'::Menu:Sales',
        requiredPolicy:'Nhl.Receipt'
      },
    ]);
  };
}