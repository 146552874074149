import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import {TeknoFileUploadComponent} from '../tekno-file-upload.component'


const routes: Routes = [
    {
      path: '',
      component: TeknoFileUploadComponent,
      canActivate: [AuthGuard, PermissionGuard],
    },
  ];

  @NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule,FormsModule,ReactiveFormsModule],
  })

  export class TeknoFileUploadRoutingModule {}