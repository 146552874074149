import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const BPM_MATERIAL_ACCEPT_REQUEST_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/bpm/material-accept',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:Bpm:MaterialAccept',
        layout: eLayoutType.application,
        parentName:'::Menu:VeraProcessWatch',
        requiredPolicy:'VeraProcess.MaterialAccept'
      },
    ]);
  };
}
