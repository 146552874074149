import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const ISLETME_BAKIM_STOCK_GROUPS_STOCK_GROUP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/stock-groups',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:StockGroups',
        layout: eLayoutType.application,
        requiredPolicy: 'VeraIntegration.StockGroups',
        parentName:'::Menu:Entegrasyon'
      },
    ]);
  };
}
