import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_LAB_ORDER_SUB_ANALYSIS_STOCK_USAGES_LAB_ORDER_SUB_ANALYSIS_STOCK_USAGE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/lab-order-sub-analysis-stock-usages',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:LabOrderSubAnalysisStockUsages',
        layout: eLayoutType.application,
        // requiredPolicy: 'Laboratory.LabOrderSubAnalysisStockUsages',
        requiredPolicy: 'İsEmriSonucGirisleAyniOldugundanMenudenKaldirildi',
        parentName:"::Menu:WorkOrderProcedures",
      },
    ]);
  };
}
