import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const SUPPLIEREVALUATION_STOK_FILE_INFORMATION_LIST_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/stock-file-information-list',
        iconClass: 'fas fa-balance-scale',
        name: '::TDE:StockInfos:DysReport:HeaderText',
        layout: eLayoutType.application,
        requiredPolicy: 'Tde.StockFileInformation',
        parentName:'::TDE:Menu:Definitions'
      },
    ]);
  };
}