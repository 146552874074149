import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_VIEW_APPROVAL_TIER_SETTINGS_VIEW_APPROVAL_TIER_SETTING_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/view-approval-tier-settings',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:ViewApprovalTierSettings',
        layout: eLayoutType.application,
        requiredPolicy: 'Laboratory.LabOrderMasters',
        parentName:'::Menu:Laboratory',

      },
    ]);
  };
}
