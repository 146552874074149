import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const QUALITY_STOCK_ANALYSIS_DETAILS_STOCK_ANALYSIS_DETAIL_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/stock-analysis-details',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:StockAnalysisDetails',
        layout: eLayoutType.application,
        requiredPolicy: 'AbpView.StockAnalysisDetails',
      },
    ]);
  };
}
