import { CoreModule } from '@abp/ng.core';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { SideMenuLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/layouts';
import { SettingManagementConfigModule } from '@abp/ng.setting-management/config';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommercialUiConfigModule } from '@volo/abp.commercial.ng.ui/config';
import { registerLocale } from '@abp/ng.core/locale';
import { AuditLoggingConfigModule } from '@volo/abp.ng.audit-logging/config';
import { FileManagementConfigModule } from '@volo/abp.ng.file-management/config';
import { IdentityServerConfigModule } from '@volo/abp.ng.identity-server/config';
import { IdentityConfigModule } from '@volo/abp.ng.identity/config';
import { LanguageManagementConfigModule } from '@volo/abp.ng.language-management/config';
import { SaasConfigModule } from '@volo/abp.ng.saas/config';
import { ChatConfigModule } from '@volo/abp.ng.chat/config';
import { AccountAdminConfigModule } from '@volo/abp.ng.account/admin/config';
import { AccountPublicConfigModule } from '@volo/abp.ng.account/public/config';
import { TextTemplateManagementConfigModule } from '@volo/abp.ng.text-template-management/config';
import { HttpErrorComponent, ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { NgSelectModule } from '@ng-select/ng-select';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTE_PROVIDER } from './route.provider';
import { TeknoFileUploadModule } from './tekno-file-upload/providers/tekno-file-upload.module';
import { ISLETME_BAKIM_DEPARTMAN_PRINTERS_DEPARTMAN_PRINTER_ROUTE_PROVIDER } from './isletme-bakim/departman-printers/departman-printer/providers/departman-printer-route.provider';
import { ISLETME_BAKIM_DEPARTMEN_DEPARTMAN_ROUTE_PROVIDER } from './isletme-bakim/departmen/departman/providers/departman-route.provider';
import { ISLETME_BAKIM_VARDIYAS_VARDIYA_ROUTE_PROVIDER } from './isletme-bakim/vardiyas/vardiya/providers/vardiya-route.provider';
import { ISLETME_BAKIM_STOKS_STOK_ROUTE_PROVIDER } from './isletme-bakim/stoks/stok/providers/stok-route.provider';
import { ISLETME_BAKIM_STOK_TIPS_STOK_TIP_ROUTE_PROVIDER } from './isletme-bakim/stok-tips/stok-tip/providers/stok-tip-route.provider';
import { ISLETME_BAKIM_STOK_BIRIMS_STOK_BIRIM_ROUTE_PROVIDER } from './isletme-bakim/stok-birims/stok-birim/providers/stok-birim-route.provider';
import { ISLETME_BAKIM_PERSONELS_PERSONEL_ROUTE_PROVIDER } from './isletme-bakim/personels/personel/providers/personel-route.provider';
import { ISLETME_BAKIM_MAKINAS_MAKINA_ROUTE_PROVIDER } from './isletme-bakim/makinas/makina/providers/makina-route.provider';
import { ISLETME_BAKIM_MAKINA_TIPS_MAKINA_TIP_ROUTE_PROVIDER } from './isletme-bakim/makina-tips/makina-tip/providers/makina-tip-route.provider';
import { ISLETME_BAKIM_ISEMRI_AMACIS_ISEMRI_AMACI_ROUTE_PROVIDER } from './isletme-bakim/is-emri-amacis/is-emri-amaci/providers/is-emri-amaci-route.provider';
import { ISLETME_BAKIM_BAKIM_EMRI_MASTERS_BAKIM_EMRI_MASTER_ROUTE_PROVIDER } from './isletme-bakim/bakim-emri-masters/bakim-emri-master/providers/bakim-emri-master-route.provider';
import { ISLETME_BAKIM_BAKIM_ARIZA_TIPS_BAKIM_ARIZA_TIP_ROUTE_PROVIDER } from './isletme-bakim/bakim-ariza-tips/bakim-ariza-tip/providers/bakim-ariza-tip-route.provider';
import { ISLETME_BAKIM_ARIZA_TALEBIS_ARIZA_TALEBI_ROUTE_PROVIDER } from './isletme-bakim/ariza-talebis/ariza-talebi/providers/ariza-talebi-route.provider';
import { TEKNOSOL_COMPONENT_ISLETME_BAKIM_ARIZA_TALEBI_REPORT_ROUTE_PROVIDER } from './teknosol-components/isletme-bakim/reports/ariza-talebi/providers/ariza-talebi-report.route.provider';
import { TEKNOSOL_COMPONENT_ISLETME_BAKIM_BAKIM_EMRI_PERSONEL_ROUTE_PROVIDER } from './teknosol-components/isletme-bakim/reports/bakim-emri-personel/providers/bakim-emri-personel.route.provider';
import { TEKNOSOL_COMPONENT_STOK_BIRIM_TEST_REPORT_ROUTE_PROVIDER } from './test-component/isletme-bakim/stok-birim/providers/stok-birim.provider';
import { TEKNOSOL_COMPONENT_STOK_TIP_TEST_REPORT_ROUTE_PROVIDER } from './test-component/isletme-bakim/stok-tip/providers/stok-tip.provider';
import { TEKNOSOL_COMPONENT_STOK_TEST_REPORT_ROUTE_PROVIDER } from './test-component/isletme-bakim/stok-test/providers/stok.provider';
import { ACCOUNTING_CURRENCIES_CURRENCY_ROUTE_PROVIDER } from './accounting/currencies/currency/providers/currency-route.provider';
import { QUALITY_EQUIPMENTS_EQUIPMENT_ROUTE_PROVIDER } from './quality/equipments/equipment/providers/equipment-route.provider';
import { QUALITY_ANALYSIS_RESULT_SETS_ANALYSIS_RESULT_SET_ROUTE_PROVIDER } from './quality/analysis-result-sets/analysis-result-set/providers/analysis-result-set-route.provider';
import { QUALITY_ANALYSIS_RESULT_SET_VALUE_CATEGORIES_ANALYSIS_RESULT_SET_VALUE_CATEGORY_ROUTE_PROVIDER } from './quality/analysis-result-set-value-categories/analysis-result-set-value-category/providers/analysis-result-set-value-category-route.provider';
import { QUALITY_ANALYSIS_RESULT_SET_VALUES_ANALYSIS_RESULT_SET_VALUE_ROUTE_PROVIDER } from './quality/analysis-result-set-values/analysis-result-set-value/providers/analysis-result-set-value-route.provider';
import { QUALITY_PRODUCTION_BATCH_SIZES_PRODUCTION_BATCH_SIZE_ROUTE_PROVIDER } from './quality/production-batch-sizes/production-batch-size/providers/production-batch-size-route.provider';
import { QUALITY_ACCEPTABLE_QUALIFICATION_LEVELS_ACCEPTABLE_QUALIFICATION_LEVEL_ROUTE_PROVIDER } from './quality/acceptable-qualification-levels/acceptable-qualification-level/providers/acceptable-qualification-level-route.provider';
import { QUALITY_ANALYSES_ANALYSIS_ROUTE_PROVIDER } from './quality/analyses/analysis/providers/analysis-route.provider';
import { QUALITY_STOCK_ANALYSES_STOCK_ANALYSIS_ROUTE_PROVIDER } from './quality/stock-analyses/stock-analysis/providers/stock-analysis-route.provider';
import { COMMON_REGIONS_REGION_ROUTE_PROVIDER } from './common/regions/region/providers/region-route.provider';
import { COMMON_CITIES_CITY_ROUTE_PROVIDER } from './common/cities/city/providers/city-route.provider';
import { BANKING_BANKS_BANK_ROUTE_PROVIDER } from './banking/banks/bank/providers/bank-route.provider';
import { BANKING_BRANCHES_BRANCH_ROUTE_PROVIDER } from './banking/branches/branch/providers/branch-route.provider';
import { BABIL_BANKING_TENANTS_BANKING_TENANT_ROUTE_PROVIDER } from './babil/banking-tenants/banking-tenant/providers/banking-tenant-route.provider';
import { BABIL_BANK_API_INFORMATIONS_BANK_API_INFORMATION_ROUTE_PROVIDER } from './babil/bank-api-informations/bank-api-information/providers/bank-api-information-route.provider';
import { BABIL_BANK_ACCOUNTS_BANK_ACCOUNT_ROUTE_PROVIDER } from './babil/bank-accounts/bank-account/providers/bank-account-route.provider';
import { BABIL_USER_BANK_ACCOUNTS_USER_BANK_ACCOUNT_ROUTE_PROVIDER } from './babil/user-bank-accounts/user-bank-account/providers/user-bank-account-route.provider';
import { BABIL_BANK_ACCOUNTS_BANK_ACCOUNT_INFORMATION_ROUTE_PROVIDER } from './babil/bank-account-informations/bank-account-information/providers/bank-account-information-route.provider';
import { BABIL_BANK_ACCOUNTS_BANK_ACCOUNT_DEFINITION_ROUTE_PROVIDER } from './babil/bank-account-definitions/providers/bank-account-definition-route.provider';
import { QUALITY_INSPECTION_LEVELS_INSPECTION_LEVEL_ROUTE_PROVIDER } from './quality/inspection-levels/inspection-level/providers/inspection-level-route.provider';
import { QUALITY_STOCK_ANALYSIS_DETAILS_STOCK_ANALYSIS_DETAIL_ROUTE_PROVIDER } from './quality/stock-analysis-details/stock-analysis-detail/providers/stock-analysis-detail-route.provider';
import { QUALITY_STOCK_ANALYSIS_DETAIL_INSTRUCTIONS_STOCK_ANALYSIS_DETAIL_INSTRUCTION_ROUTE_PROVIDER } from './quality/stock-analysis-detail-instructions/stock-analysis-detail-instruction/providers/stock-analysis-detail-instruction-route.provider';
import { ISLETME_BAKIM_STOCK_GROUPS_STOCK_GROUP_ROUTE_PROVIDER } from './isletme-bakim/stock-groups/stock-group/providers/stock-group-route.provider';
import { STOCK_QUALITY_CODES_QUALITY_CODE_ROUTE_PROVIDER } from './stock/quality-codes/quality-code/providers/quality-code-route.provider';
import { STOCK_PACKING_TYPES_PACKING_TYPE_ROUTE_PROVIDER } from './stock/packing-types/packing-type/providers/packing-type-route.provider';
import { WORK_FLOW_ROUTE_CATEGORIES_ROUTE_CATEGORY_ROUTE_PROVIDER } from './work-flow/route-categories/route-category/providers/route-category-route.provider';
import { WORK_FLOW_ROUTES_ROUTE_ROUTE_PROVIDER } from './work-flow/routes/route/providers/route-route.provider';
import { WORK_FLOW_POSITIONS_POSITION_ROUTE_PROVIDER } from './work-flow/positions/position/providers/position-route.provider';
import { QUALITY_INSTRUCTIONS_INSTRUCTION_ROUTE_PROVIDER } from './quality/instructions/instruction/providers/instruction-route.provider';
import { ABP_MODULES_SHARED_APP_ROLES_APP_ROLE_ROUTE_PROVIDER } from './abp-modules-shared/app-roles/app-role/providers/app-role-route.provider';
import { WORK_FLOW_ROUTE_START_ROLES_ROUTE_START_ROLE_ROUTE_PROVIDER } from './work-flow/route-start-roles/route-start-role/providers/route-start-role-route.provider';
import { WORK_FLOW_POSITION_ROLES_POSITION_ROLE_ROUTE_PROVIDER } from './work-flow/position-roles/position-role/providers/position-role-route.provider';
import { WORK_FLOW_TRANSITIONS_TRANSITION_ROUTE_PROVIDER } from './work-flow/transitions/transition/providers/transition-route.provider';
import { WORK_FLOW_TRANSITION_TRIGGERS_TRANSITION_TRIGGER_ROUTE_PROVIDER } from './work-flow/transition-triggers/transition-trigger/providers/transition-trigger-route.provider';

import { WORK_FLOW_TRANSITION_POST_FUNCTIONS_TRANSITION_POST_FUNCTION_ROUTE_PROVIDER } from './work-flow/transition-post-functions/transition-post-function/providers/transition-post-function-route.provider';
import { WORK_FLOW_TRANSITION_VALIDATORS_TRANSITION_VALIDATOR_ROUTE_PROVIDER } from './work-flow/transition-validators/transition-validator/providers/transition-validator-route.provider';
import { WORK_FLOW_TRANSITION_CONDITIONS_TRANSITION_CONDITION_ROUTE_PROVIDER } from './work-flow/transition-conditions/transition-condition/providers/transition-condition-route.provider';
import { WORK_FLOW_PROCESSES_PROCESS_ROUTE_PROVIDER } from './work-flow/processes/process/providers/process-route.provider';
import { WORK_FLOW_PROCESS_VALUES_PROCESS_VALUE_ROUTE_PROVIDER } from './work-flow/process-values/process-value/providers/process-value-route.provider';
import { WORK_FLOW_PROCESS_TASKS_PROCESS_TASK_ROUTE_PROVIDER } from './work-flow/process-tasks/process-task/providers/process-task-route.provider';
import { WORK_FLOW_PROCESS_TASK_ROLES_PROCESS_TASK_ROLE_ROUTE_PROVIDER } from './work-flow/process-task-roles/process-task-role/providers/process-task-role-route.provider';
import { QUALITY_QUALITY_MASTERS_QUALITY_MASTER_ROUTE_PROVIDER } from './quality/quality-masters/quality-master/providers/quality-master-route.provider';
import { QUALITY_QUALITY_MASTER_VERA_INTEGRATIONS_QUALITY_MASTER_VERA_INTEGRATION_ROUTE_PROVIDER } from './quality/quality-master-vera-integrations/quality-master-vera-integration/providers/quality-master-vera-integration-route.provider';
import { QUALITY_QUALITY_APPROVALS_QUALITY_APPROVAL_ROUTE_PROVIDER } from './quality/quality-approvals/quality-approval/providers/quality-approval-route.provider';
import { QUALITY_QUALITY_APPROVAL_STOCK_RECEIPTS_QUALITY_APPROVAL_STOCK_RECEIPT_ROUTE_PROVIDER } from './quality/quality-approval-stock-receipts/quality-approval-stock-receipt/providers/quality-approval-stock-receipt-route.provider';
import { QUALITY_QUALITY_ANALYSES_QUALITY_ANALYSIS_ROUTE_PROVIDER } from './quality/quality-analyses/quality-analysis/providers/quality-analysis-route.provider';
import { QUALITY_QUALITY_ANALYSIS_DETAILS_QUALITY_ANALYSIS_DETAIL_ROUTE_PROVIDER } from './quality/quality-analysis-details/quality-analysis-detail/providers/quality-analysis-detail-route.provider';
import { QUALITY_QUALITY_ANALYSIS_DETAIL_INSTRUCTIONS_QUALITY_ANALYSIS_DETAIL_INSTRUCTION_ROUTE_PROVIDER } from './quality/quality-analysis-detail-instructions/quality-analysis-detail-instruction/providers/quality-analysis-detail-instruction-route.provider';
import { WORK_FLOW_PROCESS_RELATIONS_PROCESS_RELATION_ROUTE_PROVIDER } from './work-flow/process-relations/process-relation/providers/process-relation-route.provider';
import { VERA_INVOICE_ROUTE_PROVIDER } from './vera-invoice/providers/vera-invoice-route.provider';
import { VERA_RECEIPT_ADVICE_ROUTE_PROVIDER } from './vera-receipt-advice/providers/vera-receipt-advice-route.provider';
import { DEVEXPRESS_REPORT_ROUTE_PROVIDER } from './devexpress-report/providers/viewer.route-provider';
import { WORK_FLOW_ROUTE_VERSIONS_ROUTE_VERSION_ROUTE_PROVIDER } from './work-flow/route-versions/route-version/providers/route-version-route.provider';
import { VERA_IBSA_ROUTE_PROVIDER } from './vera-ibsa/providers/vera-ibsa.route-provider';
import { ACCOUNTING_CURRENT_TYPE_INFORMATIONS_CURRENT_TYPE_INFORMATION_ROUTE_PROVIDER } from './accounting/current-type-informations/current-type-information/providers/current-type-information-route.provider';
import { ACCOUNTING_CURRENT_INFORMATIONS_CURRENT_INFORMATION_ROUTE_PROVIDER } from './accounting/current-informations/current-information/providers/current-information-route.provider';
import { VERA_PROCESS_PROVIDER } from './vera-process/providers/vera-process.route.provider';
import { DEVEXPRESS_REPORT_DESIGNER_ROUTE_PROVIDER } from './devexpress-report/designer/providers/designer.route-provider';
import { LABORATORY_BOOK_MASTERS_BOOK_MASTER_ROUTE_PROVIDER } from './laboratory/book-masters/book-master/providers/book-master-route.provider';
import { LABORATORY_BOOK_RECORDS_BOOK_RECORD_ROUTE_PROVIDER } from './laboratory/book-records/book-record/providers/book-record-route.provider';
import { LABORATORY_DEVICE_DEFINATIONS_DEVICE_DEFINATION_ROUTE_PROVIDER } from './laboratory/device-definations/device-defination/providers/device-defination-route.provider';
import { LABORATORY_DEVICE_RESULTS_DEVICE_RESULT_ROUTE_PROVIDER } from './laboratory/device-results/device-result/providers/device-result-route.provider';
import { BANKING_EXCHANGE_RATES_EXCHANGE_RATE_ROUTE_PROVIDER } from './banking/exchange-rates/exchange-rate/providers/exchange-rate-route.provider';
import { LABORATORY_BOOK_MASTER_ROLE_AUTHS_BOOK_MASTER_ROLE_AUTH_ROUTE_PROVIDER } from './laboratory/book-master-role-auths/book-master-role-auth/providers/book-master-role-auth-route.provider';
import { LABORATORY_BOOK_RECORD_LOGS_BOOK_RECORD_LOG_ROUTE_PROVIDER } from './laboratory/book-record-logs/book-record-log/providers/book-record-log-route.provider';
import { BPM_WORKORDER_REQUEST_PROVIDER } from './bpm/workorder-request/providers/workorder-request-route-provider';
import { BABIL_BANK_ACCOUNT_ROLES_BANK_ACCOUNT_ROLE_ROUTE_PROVIDER } from './babil/bank-account-roles/bank-account-role/providers/bank-account-role-route.provider';
import { BPM_RAW_MATERIAL_REQUEST_PROVIDER } from './bpm/raw-material/providers/raw-material-route.provider'
import { SIGNALR_MESSAGES_ROUTE_PROVIDER } from './signalr/messages/providers/messages.route.provider'
import { BPM_FORMUL_PROVIDER } from './bpm/formul/providers/formul.route.provider'
import { BPM_MATERIAL_ACCEPT_REQUEST_PROVIDER } from './bpm/material-accept/providers/material-accept.route.provider'
import { QUALITY_ANALYSES_TEST_ANALYSIS_ROUTE_PROVIDER } from './test-component/analyses/provider/analyses.router.provider';
import { BABIL_BANK_API_INFORMATIONS_BANK_API_INFORMATION_TEST_ROUTE_PROVIDER } from './test-component/babil/bank-api-informations/providers/bank-api-information-route.provider';
import { BABIL_COMPANY_TYPES_COMPANY_TYPE_ROUTE_PROVIDER } from './babil/company-types/company-type/providers/company-type-route.provider';
import { BABIL_ACCOUNT_CODES_ACCOUNT_CODE_ROUTE_PROVIDER } from './babil/account-codes/account-code/providers/account-code-route.provider';
import { BABIL_BUDGET_TYPES_BUDGET_TYPE_ROUTE_PROVIDER } from './babil/budget-types/budget-type/providers/budget-type-route.provider';
import { BABIL_COMPANIES_COMPANY_ROUTE_PROVIDER } from './babil/companies/company/providers/company-route.provider';
import { BABIL_CREDIT_FILES_CREDIT_FILE_ROUTE_PROVIDER } from './babil/credit-files/credit-file/providers/credit-file-route.provider';
import { BABIL_CREDIT_TYPES_CREDIT_TYPE_ROUTE_PROVIDER } from './babil/credit-types/credit-type/providers/credit-type-route.provider';
import { BABIL_COMPANY_BANK_ACCOUNTS_COMPANY_BANK_ACCOUNT_ROUTE_PROVIDER } from './babil/company-bank-accounts/company-bank-account/providers/company-bank-account-route.provider';
import { BABIL_MATCH_RULE_MASTERS_MATCH_RULE_MASTER_ROUTE_PROVIDER } from './babil/match-rule-masters/teknosol-match-rule-master/providers/match-rule-master-route.provider';
import { BABIL_MATCH_RULE_FILTERS_MATCH_RULE_FILTER_ROUTE_PROVIDER } from './babil/match-rule-filters/match-rule-filter/providers/match-rule-filter-route.provider';
import { BABIL_BANK_ANALYSIS_CODES_BANK_ANALYSIS_CODE_ROUTE_PROVIDER } from './babil/bank-analysis-codes/bank-analysis-code/providers/bank-analysis-code-route.provider';
import { BABIL_MATCH_RULE_FILTER_VALUES_MATCH_RULE_FILTER_VALUE_ROUTE_PROVIDER } from './babil/match-rule-filter-values/match-rule-filter-value/providers/match-rule-filter-value-route.provider'
import { BABIL_BANK_ACCOUNT_TRANSACTION_TO_VERAS_BANK_ACCOUNT_TRANSACTION_TO_VERA_ROUTE_PROVIDER } from './teknosol-components/babil/bank-account-transaction-to-veras/bank-account-transaction-to-vera/providers/bank-account-transaction-to-vera-route.provider';
import { BABIL_DEPARTMAN_OF_BABILS_DEPARTMAN_OF_BABIL_ROUTE_PROVIDER } from './babil/departman-of-babils/departman-of-babil/providers/departman-of-babil-route.provider';
import { LABORATORY_LAB_STOCK_USAGE_EXCEPTIONS_LAB_STOCK_USAGE_EXCEPTION_ROUTE_PROVIDER } from './laboratory/lab-stock-usage-exceptions/lab-stock-usage-exception/providers/lab-stock-usage-exception-route.provider';
import { LABORATORY_LAB_SUB_ANALYZES_LAB_SUB_ANALYSIS_ROUTE_PROVIDER } from './laboratory/lab-sub-analyzes/teknosol-lab-sub-analysis/providers/lab-sub-analysis-route.provider';
import { LABORATORY_ANALYSIS_LAB_SUB_ANALYZES_ANALYSIS_LAB_SUB_ANALYSIS_ROUTE_PROVIDER } from './laboratory/analysis-lab-sub-analyzes/analysis-lab-sub-analysis/providers/analysis-lab-sub-analysis-route.provider';
import { LABORATORY_ANALYSIS_LAB_SUB_ANALYSES_ANALYSIS_LAB_SUB_ANALYSIS_ROUTE_PROVIDER } from './laboratory/analysis-lab-sub-analyses/teknosol-analysis-lab-sub-analysis/providers/analysis-lab-sub-analysis-route.provider';
import { LABORATORY_LAB_ORDER_MASTERS_LAB_ORDER_MASTER_ROUTE_PROVIDER } from './laboratory/lab-order-masters/teknosol-lab-order-master/providers/lab-order-master-route.provider';
import { LABORATORY_LABORATORY_UNITS_LABORATORY_UNIT_ROUTE_PROVIDER } from './laboratory/laboratory-units/laboratory-unit/providers/laboratory-unit-route.provider';
import { LABORATORY_LAB_ORDER_CATEGORIES_LAB_ORDER_CATEGORY_ROUTE_PROVIDER } from './laboratory/lab-order-categories/teknosol-lab-order-category/providers/lab-order-category-route.provider';
import { LABORATORY_LAB_ORDER_CATEGORY_ROLES_LAB_ORDER_CATEGORY_ROLE_ROUTE_PROVIDER } from './laboratory/lab-order-category-roles/lab-order-category-role/providers/lab-order-category-role-route.provider';
import { LABORATORY_LAB_ORDER_CATEGORY_STOCK_TYPES_LAB_ORDER_CATEGORY_STOCK_TYPE_ROUTE_PROVIDER } from './laboratory/lab-order-category-stock-types/lab-order-category-stock-type/providers/lab-order-category-stock-type-route.provider';
import { LABORATORY_LAB_ORDER_MASTER_ASSIGNED_USERS_LAB_ORDER_MASTER_ASSIGNED_USER_ROUTE_PROVIDER } from './laboratory/lab-order-master-assigned-users/lab-order-master-assigned-user/providers/lab-order-master-assigned-user-route.provider';
import { LABORATORY_LAB_SAMPLE_CATEGORIES_LAB_SAMPLE_CATEGORY_ROUTE_PROVIDER } from './laboratory/lab-sample-categories/lab-sample-category/providers/lab-sample-category-route.provider';
import { LABORATORY_LAB_SAMPLE_MASTERS_LAB_SAMPLE_MASTER_ROUTE_PROVIDER } from './laboratory/lab-sample-masters/lab-sample-master/providers/lab-sample-master-route.provider';
import { LABORATORY_LAB_SAMPLE_POINT_CATEGORIES_LAB_SAMPLE_POINT_CATEGORY_ROUTE_PROVIDER } from './laboratory/lab-sample-point-categories/lab-sample-point-category/providers/lab-sample-point-category-route.provider';
import { LABORATORY_LAB_SAMPLE_POINTS_LAB_SAMPLE_POINT_ROUTE_PROVIDER } from './laboratory/lab-sample-points/teknosol-lab-sample-point/providers/lab-sample-point-route.provider';
import { LABORATORY_LAB_SAMPLE_SUB_POINTS_LAB_SAMPLE_SUB_POINT_ROUTE_PROVIDER } from './laboratory/lab-sample-sub-points/lab-sample-sub-point/providers/lab-sample-sub-point-route.provider';
import { LABORATORY_LAB_SAMPLE_DETAILS_LAB_SAMPLE_DETAIL_ROUTE_PROVIDER } from './laboratory/lab-sample-details/lab-sample-detail/providers/lab-sample-detail-route.provider';
import { LABORATORY_LAB_ORDER_SAMPLES_LAB_ORDER_SAMPLE_ROUTE_PROVIDER } from './laboratory/lab-order-samples/lab-order-sample/providers/lab-order-sample-route.provider';
import { LABORATORY_LAB_ORDER_LOG_BOOKS_LAB_ORDER_LOG_BOOK_ROUTE_PROVIDER } from './laboratory/lab-order-log-books/lab-order-log-book/providers/lab-order-log-book-route.provider';
import { LABORATORY_LAB_ORDER_SUB_ANALYSIS_FORMULAS_LAB_ORDER_SUB_ANALYSIS_FORMULA_ROUTE_PROVIDER } from './laboratory/lab-order-sub-analysis-formulas/lab-order-sub-analysis-formula/providers/lab-order-sub-analysis-formula-route.provider';
import { LABORATORY_LAB_ORDER_SUB_ANALYSIS_RESULTS_LAB_ORDER_SUB_ANALYSIS_RESULT_ROUTE_PROVIDER } from './laboratory/lab-order-sub-analysis-results/lab-order-sub-analysis-result/providers/lab-order-sub-analysis-result-route.provider';
import { LABORATORY_LAB_ORDER_SUB_ANALYSIS_STOCK_USAGES_LAB_ORDER_SUB_ANALYSIS_STOCK_USAGE_ROUTE_PROVIDER } from './laboratory/lab-order-sub-analysis-stock-usages/lab-order-sub-analysis-stock-usage/providers/lab-order-sub-analysis-stock-usage-route.provider';
import { LABORATORY_DEVICE_CATEGORIES_DEVICE_CATEGORY_ROUTE_PROVIDER } from './laboratory/device-categories/device-category/providers/device-category-route.provider';
import { LABORATORY_LAB_ORDER_SUB_ANALYSES_LAB_ORDER_SUB_ANALYSIS_ROUTE_PROVIDER } from './laboratory/lab-order-sub-analyses/lab-order-sub-analysis/providers/lab-order-sub-analysis-route.provider';
import { LABORATORY_LAB_ORDER_ANALYSES_LAB_ORDER_ANALYSIS_ROUTE_PROVIDER } from './laboratory/lab-order-analyses/lab-order-analysis/providers/lab-order-analysis-route.provider';
import { LABORATORY_LAB_ORDER_CATEGORY_GROUPS_LAB_ORDER_CATEGORY_GROUP_ROUTE_PROVIDER } from './laboratory/lab-order-category-groups/lab-order-category-group/providers/lab-order-category-group-route.provider';
import { LABORATORY_LAB_SAMPLES_LAB_SAMPLE_ROUTE_PROVIDER } from './laboratory/lab-samples/teknosol-lab-sample/providers/lab-sample-route.provider';
import { LABORATORY_LABORATORY_UNIT_LOGS_LABORATORY_UNIT_LOG_ROUTE_PROVIDER } from './laboratory/laboratory-unit-logs/laboratory-unit-log/providers/laboratory-unit-log-route.provider';
import { LABORATORY_ANALYSIS_LAB_SUB_ANALYSIS_LOGS_ANALYSIS_LAB_SUB_ANALYSIS_LOG_ROUTE_PROVIDER } from './laboratory/analysis-lab-sub-analysis-logs/analysis-lab-sub-analysis-log/providers/analysis-lab-sub-analysis-log-route.provider';
import { LABORATORY_DEVICE_CATEGORY_LOGS_DEVICE_CATEGORY_LOG_ROUTE_PROVIDER } from './laboratory/device-category-logs/device-category-log/providers/device-category-log-route.provider';
import { LABORATORY_DEVICE_DEFINATION_LOGS_DEVICE_DEFINATION_LOG_ROUTE_PROVIDER } from './laboratory/device-defination-logs/device-defination-log/providers/device-defination-log-route.provider';
import { LABORATORY_DEVICE_RESULT_LOGS_DEVICE_RESULT_LOG_ROUTE_PROVIDER } from './laboratory/device-result-logs/device-result-log/providers/device-result-log-route.provider';
import { LABORATORY_LAB_ORDER_ANALYSIS_LOGS_LAB_ORDER_ANALYSIS_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-analysis-logs/lab-order-analysis-log/providers/lab-order-analysis-log-route.provider';
import { LABORATORY_LAB_ORDER_CATEGORY_GROUP_LOGS_LAB_ORDER_CATEGORY_GROUP_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-category-group-logs/lab-order-category-group-log/providers/lab-order-category-group-log-route.provider';
import { LABORATORY_LAB_ORDER_CATEGORY_LOGS_LAB_ORDER_CATEGORY_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-category-logs/lab-order-category-log/providers/lab-order-category-log-route.provider';
import { LABORATORY_LAB_ORDER_CATEGORY_ROLE_LOGS_LAB_ORDER_CATEGORY_ROLE_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-category-role-logs/lab-order-category-role-log/providers/lab-order-category-role-log-route.provider';
import { LABORATORY_LAB_ORDER_CATEGORY_STOCK_TYPE_LOGS_LAB_ORDER_CATEGORY_STOCK_TYPE_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-category-stock-type-logs/lab-order-category-stock-type-log/providers/lab-order-category-stock-type-log-route.provider';
import { LABORATORY_LAB_ORDER_LOG_BOOK_LOGS_LAB_ORDER_LOG_BOOK_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-log-book-logs/lab-order-log-book-log/providers/lab-order-log-book-log-route.provider';
import { LABORATORY_LAB_ORDER_MASTER_ASSIGNED_USER_LOGS_LAB_ORDER_MASTER_ASSIGNED_USER_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-master-assigned-user-logs/lab-order-master-assigned-user-log/providers/lab-order-master-assigned-user-log-route.provider';
import { LABORATORY_LAB_ORDER_MASTER_LOGS_LAB_ORDER_MASTER_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-master-logs/lab-order-master-log/providers/lab-order-master-log-route.provider';
import { LABORATORY_LAB_ORDER_SAMPLE_LOGS_LAB_ORDER_SAMPLE_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-sample-logs/lab-order-sample-log/providers/lab-order-sample-log-route.provider';
import { LABORATORY_LAB_ORDER_SUB_ANALYSIS_FORMULA_LOGS_LAB_ORDER_SUB_ANALYSIS_FORMULA_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-sub-analysis-formula-logs/lab-order-sub-analysis-formula-log/providers/lab-order-sub-analysis-formula-log-route.provider';
import { LABORATORY_LAB_ORDER_SUB_ANALYSIS_LOGS_LAB_ORDER_SUB_ANALYSIS_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-sub-analysis-logs/lab-order-sub-analysis-log/providers/lab-order-sub-analysis-log-route.provider';
import { LABORATORY_LAB_ORDER_SUB_ANALYSIS_RESULT_LOGS_LAB_ORDER_SUB_ANALYSIS_RESULT_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-sub-analysis-result-logs/lab-order-sub-analysis-result-log/providers/lab-order-sub-analysis-result-log-route.provider';
import { LABORATORY_LAB_ORDER_SUB_ANALYSIS_STOCK_USAGE_LOGS_LAB_ORDER_SUB_ANALYSIS_STOCK_USAGE_LOG_ROUTE_PROVIDER } from './laboratory/lab-order-sub-analysis-stock-usage-logs/lab-order-sub-analysis-stock-usage-log/providers/lab-order-sub-analysis-stock-usage-log-route.provider';
import { LABORATORY_LAB_SAMPLE_LOGS_LAB_SAMPLE_LOG_ROUTE_PROVIDER } from './laboratory/lab-sample-logs/lab-sample-log/providers/lab-sample-log-route.provider';
import { LABORATORY_LAB_SAMPLE_POINT_LOGS_LAB_SAMPLE_POINT_LOG_ROUTE_PROVIDER } from './laboratory/lab-sample-point-logs/lab-sample-point-log/providers/lab-sample-point-log-route.provider';
import { LABORATORY_LAB_SAMPLE_SUB_POINT_LOGS_LAB_SAMPLE_SUB_POINT_LOG_ROUTE_PROVIDER } from './laboratory/lab-sample-sub-point-logs/lab-sample-sub-point-log/providers/lab-sample-sub-point-log-route.provider';
import { LABORATORY_LAB_STOCK_USAGE_EXCEPTION_LOGS_LAB_STOCK_USAGE_EXCEPTION_LOG_ROUTE_PROVIDER } from './laboratory/lab-stock-usage-exception-logs/lab-stock-usage-exception-log/providers/lab-stock-usage-exception-log-route.provider';
import { LABORATORY_LAB_SUB_ANALYSIS_LOGS_LAB_SUB_ANALYSIS_LOG_ROUTE_PROVIDER } from './laboratory/lab-sub-analysis-logs/lab-sub-analysis-log/providers/lab-sub-analysis-log-route.provider'
import { BABIL_CHECK_BILL_POSITIONS_CHECK_BILL_POSITION_ROUTE_PROVIDER } from './babil/check-bill-positions/check-bill-position/providers/check-bill-position-route.provider'
import { REPORT_FILE_ROLE_AUTHS_REPORT_FILE_ROLE_AUTH_ROUTE_PROVIDER } from './report-file-role-auths/report-file-role-auth/providers/report-file-role-auth-route.provider'
import { REPORT_GROUP_WITH_REPORT_FILES_PROVIDER } from './report-group-with-files/providers/report-group-with-report-files.provider';
import { BABIL_MATCH_RULE_FILTER_QUERY_METHOD_OPERATIONS_MATCH_RULE_FILTER_QUERY_METHOD_OPERATION_ROUTE_PROVIDER } from './babil/match-rule-filter-query-method-operations/match-rule-filter-query-method-operation/providers/match-rule-filter-query-method-operation-route.provider';
import { BABIL_BANK_ACCOUNT_TRANSACTION_TO_VERA_LOGS_BANK_ACCOUNT_TRANSACTION_TO_VERA_LOG_ROUTE_PROVIDER } from './babil/bank-account-transaction-to-vera-logs/bank-account-transaction-to-vera-log/providers/bank-account-transaction-to-vera-log-route.provider';
import { BABIL_MATCH_RULE_FILTER_VALUES_CONTROLS_MATCH_RULE_FILTER_VALUES_CONTROL_ROUTE_PROVIDER } from './babil/match-rule-filter-values-controls/teknosol-match-rule-filter-values-control/providers/match-rule-filter-values-control-route.provider';
import { LABORATORY_LAB_ORDER_MASTERS_LAB_ORDER_MASTER_RESULT_ENTRY_ROUTE_PROVIDER } from './laboratory/lab-order-master-result-entry/providers/lab-order-master-result-entry-route.provider';
import { LABORATORY_DEVICE_CALIBRATION_AND_LOG_ROUTE_PROVIDER } from './laboratory/device-calibration-and-log/providers/device-calibration-and-log-route.provider';
import { DEVICE_CALIBRATIONS_DEVICE_CALIBRATION_ROUTE_PROVIDER } from './device-calibrations/device-calibration/providers/device-calibration-route.provider';
import { APP_FM_EXTRA_DATAS_APP_FM_EXTRA_DATA_ROUTE_PROVIDER } from './app-fm-extra-datas/app-fm-extra-data/providers/app-fm-extra-data-route.provider';
import { APP_FM_EXTRA_DATA_INFOS_APP_FM_EXTRA_DATA_INFO_ROUTE_PROVIDER } from './app-fm-extra-data-infos/app-fm-extra-data-info/providers/app-fm-extra-data-info-route.provider';
import { APP_FM_GROUPS_APP_FM_GROUP_ROUTE_PROVIDER } from './app-fm-groups/app-fm-group/providers/app-fm-group-route.provider';
import { APP_FM_AUTHORITIES_APP_FM_AUTHORITY_ROUTE_PROVIDER } from './app-fm-authorities/app-fm-authority/providers/app-fm-authority-route.provider';
import { SUPPLIER_TIER_STOCK_RELATIONS_TIER_STOCK_RELATION_ROUTE_PROVIDER } from './supplier/tier-stock-relations/tier-stock-relation/providers/tier-stock-relation-route.provider';
import { DYS_MASTERS_DYS_MASTER_ROUTE_PROVIDER } from './dys-masters/dys-master/providers/dys-master-route.provider';
import { SUPPLIER_TIERS_TIER_ROUTE_PROVIDER } from './supplier/tiers/tier/providers/tier-route.provider';
import { SUPPLIEREVALUATION_GROUP_ROUTE_PROVIDER } from './supplierEvaluation/group/providers/group-route.provider';
import { SUPPLIEREVALUATION_APPROVAL_TIERS_ROUTE_PROVIDER } from './supplierEvaluation/ApprovalTiers/providers/ApprovalTiers-route.provider';
import { SUPPLIEREVALUATION_TIERAUTHORIZATION_ROUTE_PROVIDER } from './supplierEvaluation/tierAuthorizations/providers/tierAuthorizations-route.provider'
import { SUPPLIEREVALUATION_COMPANY_INFOS_ROUTE_PROVIDER } from './supplierEvaluation/companyInfos/providers/companyInfos-route.provider'
import { SUPPLIEREVALUATION_STOCK_INFOS_ROUTE_PROVIDER } from './supplierEvaluation/stockInfos/providers/stockınfos-route.provider'
import { SUPPLIEREVALUATION_STOK_FILE_INFORMATION_LIST_ROUTE_PROVIDER  } from './supplierEvaluation/StockFileInformationList/providers/StockFileInformationList-route.provider'
import { SUPPLIEREVALUATION_CUSTOMER_FILE_INFORMATION_LIST_ROUTE_PROVIDER } from './supplierEvaluation/customerFileInformationList/providers/customerFileInformationList-route.provider'
import { SUPPLIEREVALUATION_APPROVAL_TIER_LOGS_ROUTE_PROVIDER } from './supplierEvaluation/approvalTierLogs/providers/approvalTierLogs-route.provider'
import { SUPPLIEREVALUATION_SETTINGS_ROUTE_PROVIDER } from './supplierEvaluation/ayarlar/providers/ayarlar-route.provider'
import { AbpOAuthModule } from "@abp/ng.oauth";
import { AccountLayoutModule } from '@volosoft/abp.ng.theme.lepton-x/account';
import { SUPPLIEREVALUATION_TEKNOSOL_ROUTE_PROVIDER } from './supplierEvaluation/teknosol/providers/teknosol-route.provider'
import { LABORATORY_VIEW_APPROVAL_TIERS_VIEW_APPROVAL_TIER_ROUTE_PROVIDER } from './laboratory/view-approval-tiers/view-approval-tier/providers/view-approval-tier-route.provider';
import { LABORATORY_VIEW_APPROVAL_TIER_LOGS_VIEW_APPROVAL_TIER_LOG_ROUTE_PROVIDER } from './laboratory/view-approval-tier-logs/view-approval-tier-log/providers/view-approval-tier-log-route.provider';

import { LABORATORY_VIEW_APPROVAL_TIER_SETTINGS_VIEW_APPROVAL_TIER_SETTING_ROUTE_PROVIDER } from './laboratory/view-approval-tier-settings/view-approval-tier-setting/providers/view-approval-tier-setting-route.provider'
import {SUPPLIEREVALUATION_VIEWTIERAUTHORIZATION_ROUTE_PROVIDER} from './laboratory/view-tier-authorizations/view-tier-authorization/providers/view-tier-authorizations-route.provider'

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NgSelectModule,
    CommercialUiConfigModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
    TeknoFileUploadModule,
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),
    AbpOAuthModule.forRoot(),
    ThemeSharedModule.forRoot({
      httpErrorConfig: {
        errorScreen: {
          component: HttpErrorComponent,
          forWhichErrors: [401, 403, 404, 500],
          hideCloseIcon: true,
        },
      },
    }),
    CoreModule.forRoot({
      environment,
      registerLocaleFn: registerLocale(),
    }),

    ThemeLeptonXModule.forRoot(),
    SideMenuLayoutModule.forRoot(),
    IdentityConfigModule.forRoot(),
    LanguageManagementConfigModule.forRoot(),
    SaasConfigModule.forRoot(),
    AuditLoggingConfigModule.forRoot(),
    IdentityServerConfigModule.forRoot(),
    TextTemplateManagementConfigModule.forRoot(),
    SettingManagementConfigModule.forRoot(),
    ChatConfigModule.forRoot(),
    AccountLayoutModule.forRoot(),
    AccountAdminConfigModule.forRoot(),
    AccountPublicConfigModule.forRoot(),
  ],
  providers: [
    APP_ROUTE_PROVIDER,
    ISLETME_BAKIM_DEPARTMAN_PRINTERS_DEPARTMAN_PRINTER_ROUTE_PROVIDER,
    ISLETME_BAKIM_DEPARTMEN_DEPARTMAN_ROUTE_PROVIDER,
    ISLETME_BAKIM_VARDIYAS_VARDIYA_ROUTE_PROVIDER,
    ISLETME_BAKIM_STOK_TIPS_STOK_TIP_ROUTE_PROVIDER,
    ISLETME_BAKIM_STOKS_STOK_ROUTE_PROVIDER,
    ISLETME_BAKIM_STOK_BIRIMS_STOK_BIRIM_ROUTE_PROVIDER,
    ISLETME_BAKIM_PERSONELS_PERSONEL_ROUTE_PROVIDER,
    ISLETME_BAKIM_MAKINAS_MAKINA_ROUTE_PROVIDER,
    ISLETME_BAKIM_MAKINA_TIPS_MAKINA_TIP_ROUTE_PROVIDER,
    ISLETME_BAKIM_ISEMRI_AMACIS_ISEMRI_AMACI_ROUTE_PROVIDER,
    ISLETME_BAKIM_BAKIM_EMRI_MASTERS_BAKIM_EMRI_MASTER_ROUTE_PROVIDER,
    ISLETME_BAKIM_BAKIM_ARIZA_TIPS_BAKIM_ARIZA_TIP_ROUTE_PROVIDER,
    ISLETME_BAKIM_ARIZA_TALEBIS_ARIZA_TALEBI_ROUTE_PROVIDER,
    TEKNOSOL_COMPONENT_ISLETME_BAKIM_ARIZA_TALEBI_REPORT_ROUTE_PROVIDER,
    TEKNOSOL_COMPONENT_ISLETME_BAKIM_BAKIM_EMRI_PERSONEL_ROUTE_PROVIDER,
    TEKNOSOL_COMPONENT_STOK_BIRIM_TEST_REPORT_ROUTE_PROVIDER,
    TEKNOSOL_COMPONENT_STOK_TIP_TEST_REPORT_ROUTE_PROVIDER,
    TEKNOSOL_COMPONENT_STOK_TEST_REPORT_ROUTE_PROVIDER,
    VERA_INVOICE_ROUTE_PROVIDER,
    VERA_RECEIPT_ADVICE_ROUTE_PROVIDER,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    ACCOUNTING_CURRENCIES_CURRENCY_ROUTE_PROVIDER,
    QUALITY_EQUIPMENTS_EQUIPMENT_ROUTE_PROVIDER,

    QUALITY_ANALYSIS_RESULT_SETS_ANALYSIS_RESULT_SET_ROUTE_PROVIDER,
    QUALITY_ANALYSIS_RESULT_SET_VALUE_CATEGORIES_ANALYSIS_RESULT_SET_VALUE_CATEGORY_ROUTE_PROVIDER,
    QUALITY_ANALYSIS_RESULT_SET_VALUES_ANALYSIS_RESULT_SET_VALUE_ROUTE_PROVIDER,
    QUALITY_PRODUCTION_BATCH_SIZES_PRODUCTION_BATCH_SIZE_ROUTE_PROVIDER,
    QUALITY_ACCEPTABLE_QUALIFICATION_LEVELS_ACCEPTABLE_QUALIFICATION_LEVEL_ROUTE_PROVIDER,
    QUALITY_ANALYSES_ANALYSIS_ROUTE_PROVIDER,
    QUALITY_STOCK_ANALYSES_STOCK_ANALYSIS_ROUTE_PROVIDER,
    QUALITY_INSPECTION_LEVELS_INSPECTION_LEVEL_ROUTE_PROVIDER,
    COMMON_REGIONS_REGION_ROUTE_PROVIDER,
    COMMON_CITIES_CITY_ROUTE_PROVIDER,
    BANKING_BANKS_BANK_ROUTE_PROVIDER,
    BANKING_BRANCHES_BRANCH_ROUTE_PROVIDER,
    BABIL_BANKING_TENANTS_BANKING_TENANT_ROUTE_PROVIDER,
    BABIL_BANK_API_INFORMATIONS_BANK_API_INFORMATION_ROUTE_PROVIDER,
    BABIL_BANK_ACCOUNTS_BANK_ACCOUNT_ROUTE_PROVIDER,
    BABIL_BANK_ACCOUNTS_BANK_ACCOUNT_DEFINITION_ROUTE_PROVIDER,
    //BABIL_USER_BANK_ACCOUNTS_USER_BANK_ACCOUNT_ROUTE_PROVIDER,
    QUALITY_STOCK_ANALYSIS_DETAILS_STOCK_ANALYSIS_DETAIL_ROUTE_PROVIDER,
    QUALITY_STOCK_ANALYSIS_DETAIL_INSTRUCTIONS_STOCK_ANALYSIS_DETAIL_INSTRUCTION_ROUTE_PROVIDER,
    ISLETME_BAKIM_STOCK_GROUPS_STOCK_GROUP_ROUTE_PROVIDER,
    STOCK_QUALITY_CODES_QUALITY_CODE_ROUTE_PROVIDER,
    STOCK_PACKING_TYPES_PACKING_TYPE_ROUTE_PROVIDER,

    WORK_FLOW_ROUTE_CATEGORIES_ROUTE_CATEGORY_ROUTE_PROVIDER,
    WORK_FLOW_ROUTES_ROUTE_ROUTE_PROVIDER,
    WORK_FLOW_POSITIONS_POSITION_ROUTE_PROVIDER,
    QUALITY_INSTRUCTIONS_INSTRUCTION_ROUTE_PROVIDER,
    // ABP_MODULES_SHARED_APP_ROLES_APP_ROLE_ROUTE_PROVIDER,
    WORK_FLOW_ROUTE_START_ROLES_ROUTE_START_ROLE_ROUTE_PROVIDER,
    // WORK_FLOW_POSITION_ROLES_POSITION_ROLE_ROUTE_PROVIDER,
    WORK_FLOW_TRANSITIONS_TRANSITION_ROUTE_PROVIDER,
    WORK_FLOW_TRANSITION_TRIGGERS_TRANSITION_TRIGGER_ROUTE_PROVIDER,

    WORK_FLOW_TRANSITION_POST_FUNCTIONS_TRANSITION_POST_FUNCTION_ROUTE_PROVIDER,
    WORK_FLOW_TRANSITION_VALIDATORS_TRANSITION_VALIDATOR_ROUTE_PROVIDER,
    WORK_FLOW_TRANSITION_CONDITIONS_TRANSITION_CONDITION_ROUTE_PROVIDER,
    // ABP_MODULES_SHARED_APP_USERS_APP_USER_ROUTE_PROVIDER,
    WORK_FLOW_PROCESSES_PROCESS_ROUTE_PROVIDER,
    WORK_FLOW_PROCESS_VALUES_PROCESS_VALUE_ROUTE_PROVIDER,
    WORK_FLOW_PROCESS_TASKS_PROCESS_TASK_ROUTE_PROVIDER,
    WORK_FLOW_PROCESS_TASK_ROLES_PROCESS_TASK_ROLE_ROUTE_PROVIDER,
    QUALITY_QUALITY_MASTERS_QUALITY_MASTER_ROUTE_PROVIDER,
    QUALITY_QUALITY_MASTER_VERA_INTEGRATIONS_QUALITY_MASTER_VERA_INTEGRATION_ROUTE_PROVIDER,
    QUALITY_QUALITY_APPROVALS_QUALITY_APPROVAL_ROUTE_PROVIDER,
    QUALITY_QUALITY_APPROVAL_STOCK_RECEIPTS_QUALITY_APPROVAL_STOCK_RECEIPT_ROUTE_PROVIDER,
    QUALITY_QUALITY_ANALYSES_QUALITY_ANALYSIS_ROUTE_PROVIDER,
    QUALITY_QUALITY_ANALYSIS_DETAILS_QUALITY_ANALYSIS_DETAIL_ROUTE_PROVIDER,
    QUALITY_QUALITY_ANALYSIS_DETAIL_INSTRUCTIONS_QUALITY_ANALYSIS_DETAIL_INSTRUCTION_ROUTE_PROVIDER,
    WORK_FLOW_PROCESS_RELATIONS_PROCESS_RELATION_ROUTE_PROVIDER,

    WORK_FLOW_ROUTE_VERSIONS_ROUTE_VERSION_ROUTE_PROVIDER,
    VERA_IBSA_ROUTE_PROVIDER,
    LABORATORY_BOOK_MASTERS_BOOK_MASTER_ROUTE_PROVIDER,
    LABORATORY_BOOK_RECORDS_BOOK_RECORD_ROUTE_PROVIDER,
    LABORATORY_DEVICE_DEFINATIONS_DEVICE_DEFINATION_ROUTE_PROVIDER,
    LABORATORY_DEVICE_RESULTS_DEVICE_RESULT_ROUTE_PROVIDER,
    BANKING_EXCHANGE_RATES_EXCHANGE_RATE_ROUTE_PROVIDER,
    LABORATORY_BOOK_MASTER_ROLE_AUTHS_BOOK_MASTER_ROLE_AUTH_ROUTE_PROVIDER,
    LABORATORY_BOOK_RECORD_LOGS_BOOK_RECORD_LOG_ROUTE_PROVIDER,
    BPM_WORKORDER_REQUEST_PROVIDER,
    BABIL_BANK_ACCOUNT_ROLES_BANK_ACCOUNT_ROLE_ROUTE_PROVIDER,
    BPM_RAW_MATERIAL_REQUEST_PROVIDER,
    BPM_FORMUL_PROVIDER,
    BPM_MATERIAL_ACCEPT_REQUEST_PROVIDER,
    QUALITY_ANALYSES_TEST_ANALYSIS_ROUTE_PROVIDER,
    BABIL_COMPANY_TYPES_COMPANY_TYPE_ROUTE_PROVIDER,
    BABIL_ACCOUNT_CODES_ACCOUNT_CODE_ROUTE_PROVIDER,
    BABIL_BUDGET_TYPES_BUDGET_TYPE_ROUTE_PROVIDER,
    BABIL_COMPANIES_COMPANY_ROUTE_PROVIDER,
    BABIL_CREDIT_FILES_CREDIT_FILE_ROUTE_PROVIDER,
    BABIL_CREDIT_TYPES_CREDIT_TYPE_ROUTE_PROVIDER,
    BABIL_COMPANY_BANK_ACCOUNTS_COMPANY_BANK_ACCOUNT_ROUTE_PROVIDER,
    BABIL_MATCH_RULE_MASTERS_MATCH_RULE_MASTER_ROUTE_PROVIDER,
    BABIL_MATCH_RULE_FILTERS_MATCH_RULE_FILTER_ROUTE_PROVIDER,
    BABIL_BANK_ANALYSIS_CODES_BANK_ANALYSIS_CODE_ROUTE_PROVIDER,
    BABIL_BANK_ACCOUNT_TRANSACTION_TO_VERAS_BANK_ACCOUNT_TRANSACTION_TO_VERA_ROUTE_PROVIDER,
    BABIL_MATCH_RULE_FILTER_VALUES_MATCH_RULE_FILTER_VALUE_ROUTE_PROVIDER,
    BABIL_DEPARTMAN_OF_BABILS_DEPARTMAN_OF_BABIL_ROUTE_PROVIDER,
    LABORATORY_LAB_STOCK_USAGE_EXCEPTIONS_LAB_STOCK_USAGE_EXCEPTION_ROUTE_PROVIDER,
    LABORATORY_LAB_SUB_ANALYZES_LAB_SUB_ANALYSIS_ROUTE_PROVIDER,
    LABORATORY_ANALYSIS_LAB_SUB_ANALYZES_ANALYSIS_LAB_SUB_ANALYSIS_ROUTE_PROVIDER,
    LABORATORY_ANALYSIS_LAB_SUB_ANALYSES_ANALYSIS_LAB_SUB_ANALYSIS_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_MASTERS_LAB_ORDER_MASTER_ROUTE_PROVIDER,
    LABORATORY_LABORATORY_UNITS_LABORATORY_UNIT_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_CATEGORIES_LAB_ORDER_CATEGORY_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_CATEGORY_ROLES_LAB_ORDER_CATEGORY_ROLE_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_CATEGORY_STOCK_TYPES_LAB_ORDER_CATEGORY_STOCK_TYPE_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_MASTER_ASSIGNED_USERS_LAB_ORDER_MASTER_ASSIGNED_USER_ROUTE_PROVIDER,
    LABORATORY_LAB_SAMPLE_CATEGORIES_LAB_SAMPLE_CATEGORY_ROUTE_PROVIDER,
    LABORATORY_LAB_SAMPLE_MASTERS_LAB_SAMPLE_MASTER_ROUTE_PROVIDER,
    LABORATORY_LAB_SAMPLE_POINT_CATEGORIES_LAB_SAMPLE_POINT_CATEGORY_ROUTE_PROVIDER,
    LABORATORY_LAB_SAMPLE_POINTS_LAB_SAMPLE_POINT_ROUTE_PROVIDER,
    LABORATORY_LAB_SAMPLE_SUB_POINTS_LAB_SAMPLE_SUB_POINT_ROUTE_PROVIDER,
    LABORATORY_LAB_SAMPLE_DETAILS_LAB_SAMPLE_DETAIL_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_SAMPLES_LAB_ORDER_SAMPLE_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_LOG_BOOKS_LAB_ORDER_LOG_BOOK_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_SUB_ANALYSIS_FORMULAS_LAB_ORDER_SUB_ANALYSIS_FORMULA_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_SUB_ANALYSIS_RESULTS_LAB_ORDER_SUB_ANALYSIS_RESULT_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_SUB_ANALYSIS_STOCK_USAGES_LAB_ORDER_SUB_ANALYSIS_STOCK_USAGE_ROUTE_PROVIDER,
    LABORATORY_DEVICE_CATEGORIES_DEVICE_CATEGORY_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_SUB_ANALYSES_LAB_ORDER_SUB_ANALYSIS_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_ANALYSES_LAB_ORDER_ANALYSIS_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_CATEGORY_GROUPS_LAB_ORDER_CATEGORY_GROUP_ROUTE_PROVIDER,
    LABORATORY_LAB_SAMPLES_LAB_SAMPLE_ROUTE_PROVIDER,
    LABORATORY_LABORATORY_UNIT_LOGS_LABORATORY_UNIT_LOG_ROUTE_PROVIDER,
    LABORATORY_ANALYSIS_LAB_SUB_ANALYSIS_LOGS_ANALYSIS_LAB_SUB_ANALYSIS_LOG_ROUTE_PROVIDER,
    LABORATORY_DEVICE_CATEGORY_LOGS_DEVICE_CATEGORY_LOG_ROUTE_PROVIDER,
    LABORATORY_DEVICE_DEFINATION_LOGS_DEVICE_DEFINATION_LOG_ROUTE_PROVIDER,
    LABORATORY_DEVICE_RESULT_LOGS_DEVICE_RESULT_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_ANALYSIS_LOGS_LAB_ORDER_ANALYSIS_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_CATEGORY_GROUP_LOGS_LAB_ORDER_CATEGORY_GROUP_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_CATEGORY_LOGS_LAB_ORDER_CATEGORY_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_CATEGORY_ROLE_LOGS_LAB_ORDER_CATEGORY_ROLE_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_CATEGORY_STOCK_TYPE_LOGS_LAB_ORDER_CATEGORY_STOCK_TYPE_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_LOG_BOOK_LOGS_LAB_ORDER_LOG_BOOK_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_MASTER_ASSIGNED_USER_LOGS_LAB_ORDER_MASTER_ASSIGNED_USER_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_MASTER_LOGS_LAB_ORDER_MASTER_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_SAMPLE_LOGS_LAB_ORDER_SAMPLE_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_SUB_ANALYSIS_FORMULA_LOGS_LAB_ORDER_SUB_ANALYSIS_FORMULA_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_SUB_ANALYSIS_LOGS_LAB_ORDER_SUB_ANALYSIS_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_SUB_ANALYSIS_RESULT_LOGS_LAB_ORDER_SUB_ANALYSIS_RESULT_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_SUB_ANALYSIS_STOCK_USAGE_LOGS_LAB_ORDER_SUB_ANALYSIS_STOCK_USAGE_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_SAMPLE_LOGS_LAB_SAMPLE_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_SAMPLE_POINT_LOGS_LAB_SAMPLE_POINT_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_SAMPLE_SUB_POINT_LOGS_LAB_SAMPLE_SUB_POINT_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_STOCK_USAGE_EXCEPTION_LOGS_LAB_STOCK_USAGE_EXCEPTION_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_SUB_ANALYSIS_LOGS_LAB_SUB_ANALYSIS_LOG_ROUTE_PROVIDER,
    BABIL_CHECK_BILL_POSITIONS_CHECK_BILL_POSITION_ROUTE_PROVIDER,
    DEVEXPRESS_REPORT_ROUTE_PROVIDER,
    DEVEXPRESS_REPORT_DESIGNER_ROUTE_PROVIDER,
    REPORT_GROUP_WITH_REPORT_FILES_PROVIDER,
    REPORT_FILE_ROLE_AUTHS_REPORT_FILE_ROLE_AUTH_ROUTE_PROVIDER,
    BABIL_MATCH_RULE_FILTER_QUERY_METHOD_OPERATIONS_MATCH_RULE_FILTER_QUERY_METHOD_OPERATION_ROUTE_PROVIDER,
    BABIL_BANK_ACCOUNT_TRANSACTION_TO_VERA_LOGS_BANK_ACCOUNT_TRANSACTION_TO_VERA_LOG_ROUTE_PROVIDER,
    LABORATORY_LAB_ORDER_MASTERS_LAB_ORDER_MASTER_RESULT_ENTRY_ROUTE_PROVIDER,
    BABIL_MATCH_RULE_FILTER_VALUES_CONTROLS_MATCH_RULE_FILTER_VALUES_CONTROL_ROUTE_PROVIDER,
    DEVICE_CALIBRATIONS_DEVICE_CALIBRATION_ROUTE_PROVIDER,
    APP_FM_EXTRA_DATAS_APP_FM_EXTRA_DATA_ROUTE_PROVIDER,
    APP_FM_EXTRA_DATA_INFOS_APP_FM_EXTRA_DATA_INFO_ROUTE_PROVIDER,
    APP_FM_GROUPS_APP_FM_GROUP_ROUTE_PROVIDER,
    APP_FM_AUTHORITIES_APP_FM_AUTHORITY_ROUTE_PROVIDER,
    SUPPLIER_TIER_STOCK_RELATIONS_TIER_STOCK_RELATION_ROUTE_PROVIDER,
    DYS_MASTERS_DYS_MASTER_ROUTE_PROVIDER,
    SUPPLIER_TIERS_TIER_ROUTE_PROVIDER,
    SUPPLIEREVALUATION_GROUP_ROUTE_PROVIDER,
    SUPPLIEREVALUATION_APPROVAL_TIERS_ROUTE_PROVIDER,
    SUPPLIEREVALUATION_TIERAUTHORIZATION_ROUTE_PROVIDER,
    SUPPLIEREVALUATION_COMPANY_INFOS_ROUTE_PROVIDER,
    SUPPLIEREVALUATION_STOCK_INFOS_ROUTE_PROVIDER,
    SUPPLIEREVALUATION_STOK_FILE_INFORMATION_LIST_ROUTE_PROVIDER,
    SUPPLIEREVALUATION_CUSTOMER_FILE_INFORMATION_LIST_ROUTE_PROVIDER,
    SUPPLIEREVALUATION_APPROVAL_TIER_LOGS_ROUTE_PROVIDER,
    SUPPLIEREVALUATION_SETTINGS_ROUTE_PROVIDER,
    SUPPLIEREVALUATION_TEKNOSOL_ROUTE_PROVIDER,
    LABORATORY_VIEW_APPROVAL_TIERS_VIEW_APPROVAL_TIER_ROUTE_PROVIDER,
    LABORATORY_VIEW_APPROVAL_TIER_LOGS_VIEW_APPROVAL_TIER_LOG_ROUTE_PROVIDER,
    LABORATORY_VIEW_APPROVAL_TIER_SETTINGS_VIEW_APPROVAL_TIER_SETTING_ROUTE_PROVIDER,
    SUPPLIEREVALUATION_VIEWTIERAUTHORIZATION_ROUTE_PROVIDER,
    LABORATORY_DEVICE_CALIBRATION_AND_LOG_ROUTE_PROVIDER
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
