import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import {  ThemeLeptonXModule } from '@volosoft/abp.ng.theme.lepton-x';
import { DxDropDownButtonModule, DxScrollViewModule } from 'devextreme-angular';
import { DxiItemModule, DxoScrollingModule } from 'devextreme-angular/ui/nested';
import { TeknoFileUploadComponent } from '../tekno-file-upload.component';
import { TeknoFileUploadRoutingModule } from './tekno-file-upload-routing.module';

@NgModule({
  declarations: [TeknoFileUploadComponent],
  imports: [
    TeknoFileUploadRoutingModule,
    CoreModule,
    ThemeSharedModule,
    ThemeLeptonXModule,
    CommercialUiModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    FormsModule,
    ReactiveFormsModule,
    DxiItemModule,
    DxDropDownButtonModule,
      DxScrollViewModule,
      DxoScrollingModule,
  ],
  exports:[
    TeknoFileUploadComponent
  ]
})
export class TeknoFileUploadModule {}
