import { mapEnumToOptions } from '@abp/ng.core';

export enum DefinationDataTypes {
  Belirtilmedi = 0,
  Stok = 1,
  Mensei = 2,
  Cari = 3,
  MakinaKodu= 4,
  MakinaAdi= 5,
  GorusulecekKisi= 6,
  StokTipi= 7,
  StokAdi= 8,
  StokKodu=9,
  LabSampleNumber=10,
  LabOrderMasterNumber=11,
  CariAdi=12,
  CariKodu=13,
  CariTipi=14,
  StokMensei=15,
}

export const definationDataTypesOptions = mapEnumToOptions(DefinationDataTypes);
