import type { AppFmExtraDataInfoCreateDto, AppFmExtraDataInfoDto, AppFmExtraDataInfoUpdateDto, GetAppFmExtraDataInfosInput } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppFmExtraDataInfoService {
  apiName = 'Default';

  create = (input: AppFmExtraDataInfoCreateDto) =>
    this.restService.request<any, AppFmExtraDataInfoDto>({
      method: 'POST',
      url: '/api/app/app-fm-extra-data-infos',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/app-fm-extra-data-infos/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, AppFmExtraDataInfoDto>({
      method: 'GET',
      url: `/api/app/app-fm-extra-data-infos/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: GetAppFmExtraDataInfosInput) =>
    this.restService.request<any, PagedResultDto<AppFmExtraDataInfoDto>>({
      method: 'GET',
      url: '/api/app/app-fm-extra-data-infos',
      params: { 
        filterText: input.filterText, 
        kaynak: input.kaynak, 
        tanim: input.tanim, 
        veriTipi: input.veriTipi,
        sorting: input.sorting, 
        skipCount: input.skipCount, 
        maxResultCount: input.maxResultCount 
      },
    },
    { apiName: this.apiName });

  update = (id: string, input: AppFmExtraDataInfoUpdateDto) =>
    this.restService.request<any, AppFmExtraDataInfoDto>({
      method: 'PUT',
      url: `/api/app/app-fm-extra-data-infos/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  getKaynakId = (kaynakId:string)=>
    this.restService.request<any, AppFmExtraDataInfoDto>({
      method: 'GET',
      url:`/api/app/app-fm-extra-data-infos/getExtra/${kaynakId}`,
    },
    {apiName: this.apiName });
  constructor(private restService: RestService) {}
}
