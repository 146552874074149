import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';
import {
  AppFmExtraDataWithExtraInfoForDto,
  DirectoryIdWithContentDto,
  FileShowDto,
  FmRelationDto,
  GetDysMasterAndExtraInput,
  GetFmRelationsInput,
  GetTeknoFileUploadInput,
  InputDysSystemDto,
  OutputDysSystemDto,
  TeknoFileUpdloadDto,
  TeknoFileUpdloadInsertDto,
} from './models';
import {  FileDescriptorDto } from '@volo/abp.ng.file-management/proxy';
import { FmRelationDirectoryContentDto } from '@proxy/fm-relations/models';
import { AppFmExtraDataWithNavigationPropertiesDto } from '@proxy/app-fm-extra-datas/models';

@Injectable({
  providedIn: 'root',
})
export class TeknoFileUploadService {
  apiName = 'Default';
  isUploadFile:any=false;
  isDeleteFile:any=false;
  isDownloadFile:any=false;
  isShowFile:any=false;
  ////debugger;
  upload = (input: TeknoFileUpdloadInsertDto) =>
    this.restService.request<any, DirectoryIdWithContentDto>(
      {
        method: 'POST',
        // url: `/api/app/document-relationships`,
        url: `/api/app/fm-relations/insert-blob`,
        body: input,
      },
      { apiName: this.apiName }
    );

  delete = (id: string) =>
    this.restService.request<any, void>(
      {
        method: 'DELETE',
        // url: `/api/app/document-relationships/${id}`,
        url: `/api/app/fm-relations/${id}`,
      },
      { apiName: this.apiName }
    ); 

  getFileInfo = (id: string) =>
    this.restService.request<any, FileDescriptorDto>(
      {
        method: 'GET',
        url: `/api/app/fm-relations/getfileinfo/${id}`,
      },
      { apiName: this.apiName }
    );

    getFile = (id: string) =>
    this.restService.request<any, string>(
      {
        method: 'GET',
        url: `/api/app/fm-relations/getfile/${id}`,
      },
      { apiName: this.apiName }
    );

  getDirectoryContents = (input: GetFmRelationsInput) =>
    this.restService.request<any, PagedResultDto<FmRelationDirectoryContentDto>>(
      {
        method: 'GET',
        url: `/api/app/fm-relations/get-yetkili-fmRelation-with-group`,
        params: {
          relationKey: input.relationKey,
          filterText: input.filterText,
          fmDirectoryDescriptorId: input.fmDirectoryDescriptorId,
          relationType: input.relationType,
          description:input.description,
        },
      },
      { apiName: this.apiName }
    );
 
  getList = (input: GetTeknoFileUploadInput) =>
    this.restService.request<any, PagedResultDto<TeknoFileUpdloadDto>>(
      {
        method: 'GET',
        // url: `/api/app/document-relationships`,
        url: `/api/app/fm-relations`,
        params: {
          filterText: input.filterText,
          name: input.name,
          relationType: input.relationType,
          tags: input.tags,
          mimeType: input.mimeType,
          content: input.content,
          sorting: input.sorting,
          skipCount: input.skipCount,
          maxResultCount: input.maxResultCount,
        },
      },
      { apiName: this.apiName }
    );
//Master Eklendi
//************************************************************************************ */
GetExtraDataAndExtraDataInfoToParamCode = (input: AppFmExtraDataWithExtraInfoForDto) =>
this.restService.request<any, PagedResultDto<AppFmExtraDataWithNavigationPropertiesDto>>(
  {
    method: 'GET',
    // url: `/api/app/document-relationships`,
    url: '/api/app/dys-masters/getExtraDataAndExtraDataInfoToParamCode',
    params: { 
      filterText: input.filterText,
      relationId:input.relationId, 
      kaynak: input.kaynak, 
      tanim: input.tanim, 
      veriTipi: input.veriTipi,
      paramcode:input.paramCode ,
      sorting: input.sorting, 
      skipCount: input.skipCount, 
      maxResultCount: input.maxResultCount },
 
  },
  { apiName: this.apiName }
);
CreateDysSystemAsync = (input: InputDysSystemDto) =>
this.restService.request<any, OutputDysSystemDto>(
  {
    method: 'POST',
    // url: `/api/app/document-relationships`,
    url: '/api/app/dys-masters/createDysSystemAsync',
    body: input,
  },
  { apiName: this.apiName }
);
deleteFmRelation = (fmRelationID: string) =>
this.restService.request<any, void>(
  {
    method: 'DELETE',
    // url: `/api/app/document-relationships/${id}`,
    url: `/api/app/fm-relations/deleteFmRelationAsync/${fmRelationID}`,
  },
  { apiName: this.apiName }
); 
getListDysAsync= (input: GetDysMasterAndExtraInput) =>
this.restService.request<any, PagedResultDto<GetDysMasterAndExtraInput>>(
  {
    method: 'GET',
    // url: `/api/app/document-relationships`,
    url: '/api/app/dys-masters/GetListDysAsync',
    params: {filterText:input.filterText, relationId:input.relationId, kaynak: input.kaynak, tanim: input.tanim, veriTipi: input.veriTipi,paramcode:input.paramCode },
 
  },
  { apiName: this.apiName }
);

showFile= (id: string) =>
this.restService.request<any, FileShowDto>(
  {
    method: 'GET',
    // url: `/api/app/document-relationships`,
    url: `/api/app/fm-relations/showFile/${id}`,
  
  },
  { apiName: this.apiName }
);
  constructor(private restService: RestService) {}
}
