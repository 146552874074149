import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const BABIL_BANK_ACCOUNT_ROLES_BANK_ACCOUNT_ROLE_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/bank-account-roles',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:BankAccountRoles',
        layout: eLayoutType.application,
        requiredPolicy: 'Babil.BankAccountRoles',
        parentName:"::Menu:Babil:Definitions"
      },
    ]);
  };
}
