import { ABP, ListResultDto, ListService, PagedResultDto, TrackByService } from '@abp/ng.core';
import {ChangeDetectionStrategy,Component,ElementRef,EventEmitter,Input,OnInit,Output,ViewChild,} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { filter, finalize, switchMap, tap } from 'rxjs/operators';
import { from } from 'rxjs';
import {GetTeknoFileUploadInput,TeknoFileUpdloadDto,TeknoFileUpdloadInsertDto,FmRelationDto,GetFmRelationsInput,InputDysSystemDto,AppFmExtraDataWithExtraInfoForDto,} from '../proxy/TeknoFileUpload/models';
import { TeknoFileUploadService } from '../proxy/TeknoFileUpload/tekno-file-upload.service';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';

import { TeknoFileUploadTeknosolService } from '@proxy/TeknoFileUpload/tekno-file-upload.teknosol.service';
import { AppFmExtraDataInfoService } from '@proxy/app-fm-extra-data-infos/app-fm-extra-data-info.service';
import {AppFmExtraDataDto,AppFmExtraDataWithNavigationPropertiesDto,} from '@proxy/app-fm-extra-datas/models';
import { AppFmGroupService } from '@proxy/app-fm-groups/app-fm-group.service';
import { AppFmGroupDto, GetAppFmGroupsInput } from '@proxy/app-fm-groups/models';
import {AppFmExtraDataInfoDto,GetAppFmExtraDataInfosInput,} from '@proxy/app-fm-extra-data-infos/models';
import { AppFmExtraDataService } from '@proxy/app-fm-extra-datas/app-fm-extra-data.service';
import { FormArray, FormControl } from '@angular/forms';
import { MatSortModule, Sort } from '@angular/material/sort';
import { DefinationDataTypes } from '@proxy/app-fm-extra-data-infos/defination-data-types.enum';
import { definationDataTypesOptions } from '@proxy/app-fm-extra-data-infos/defination-data-types.enum';
import { ResourceDataType } from '@proxy/app-fm-extra-data-infos/resource-data-type.enum';
import { FmRelationDirectoryContentDto } from '@proxy/fm-relations/models';
import { DysMasterService } from '@proxy/dys-masters/dys-master.service';
import { DysMasterDto } from '@proxy/dys-masters/models';
import { __awaiter } from 'tslib';
@Component({
  selector: 'tekno-file-upload',
  templateUrl: './tekno-file-upload.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ListService],
  styleUrls: ['tekno-file-upload.component.scss'],
  
  //styleUrls: ['./style.scss']
 // styles: [],
})
export class TeknoFileUploadComponent implements OnInit {
  @Input() masterDirectory: string;
  @Input() subDirectory: string;
  @Input() relationKey: string;
  @Input() relationType: string;
  @Input() ddd?: number;
  @Input() paramobj: any = {};
  @Input() pDirectoryId: string;
  @Input('matSortActive')
  active: string;
  @Output() isDataExistInList = new EventEmitter();
  @Output() fmDirectoryDescriptorId = new EventEmitter();
  appFmExtraData: AppFmExtraDataDto[];
  appFmExtraArray: AppFmExtraDataDto[];
  appFmGroupID: string;
  appFmExtraDataDto: AppFmExtraDataDto;
  fmDirectoryId: string;
  creator: string;
  creationTime: string;
  missingFile: boolean;
  documentDate: string;
  documentValdtyDate: string;
  validityDateWarningDay: number;
  fileInsertDto: TeknoFileUpdloadInsertDto;
  fileToUpload: File = null;
  canInsert: boolean;
  form: FormGroup;
  buyTicketForm: FormGroup;
  filters = {} as GetFmRelationsInput;
  filtersExtra = {} as GetAppFmExtraDataInfosInput;
  filtersExtraDataForParamCode = {} as AppFmExtraDataWithExtraInfoForDto;
  filtersGroup = {} as GetAppFmGroupsInput;
  submitEnabled: boolean = false;
  directoryId: string;
  data: PagedResultDto<FmRelationDirectoryContentDto> = {
    items: [],
    totalCount: 0,
  };
  dataDysMaster: PagedResultDto<DysMasterDto> = {
    items: [],
    totalCount: 0,
  };
  dataInfo: PagedResultDto<AppFmExtraDataInfoDto> = {
    items: [],
    totalCount: 0,
  };
  dataExtraDataForParamCode: PagedResultDto<AppFmExtraDataWithNavigationPropertiesDto> = {
    items: [],
    totalCount: 0,
  };
  groupItem: PagedResultDto<AppFmGroupDto> = {
    items: [],
    totalCount: 0,
  };
  blob: Blob;
  mimeType: string;
  fileName: string;
  isFiltersHidden = true;
  showData: boolean = false;
  isModalBusy = false;
  definationDataTypesOptions = definationDataTypesOptions;
  isModalOpen = false;
  appFmGroupId?: string;
  selected?: TeknoFileUpdloadDto;
  selectedName?: AppFmGroupDto;
  groupName?: string;
  isVisible: boolean = false;
  title = 'ReactiveForm';
  reactiveForm: FormGroup;
  @ViewChild('filee', { static: false })
  myFileInput: ElementRef;
  @ViewChild('fileDescription', { static: false }) fileDescription: ElementRef;
  @ViewChild('myTable') table;
  private isLoading: boolean = false;
  constructor(
    public readonly list: ListService,
    public readonly service: TeknoFileUploadService,
    private confirmation: ConfirmationService,
    private fb: FormBuilder,
    public teknosolFileUploadService: TeknoFileUploadTeknosolService,
    public readonly fmGroupService: AppFmGroupService,
    public readonly dysMasterService: DysMasterService,
    public readonly extraInfoService: AppFmExtraDataInfoService
  ) {}
  ngOnInit(): void {
    this.canInsert = true;
    this.buildForm();
    this.getDysExtraDataDataInfo();
    this.getDysExtraData();
    this.getDysMaster();
    this.getGroup();
    this.getContentsOfDirectory();
  }
  getContentsOfDirectory() {
    debugger
    this.filters.fmDirectoryDescriptorId = this.directoryId ?? this.pDirectoryId;
    this.filters.relationKey = this.relationKey;
    const getData = (query: ABP.PageQueryParams) =>
      this.service.getDirectoryContents({
        ...query,
        ...this.filters,
        filterText: query.filter,
      });
    const setData = (list: PagedResultDto<FmRelationDirectoryContentDto>) => (
      (this.data = list),
      this.data.items.forEach((x) => {
        this.fmGroupService.get(x.appFmGroupID).subscribe((y) => {
          if (y.id === x.appFmGroupID) {
            x.appFmGroupID = y.name;
          }
        });
      })
    );

    this.list.hookToQuery(getData).subscribe(setData);
    this.showData = true;
  }
  onSelectName({ id, name }): void {
    this.selected = name;
    this.groupName = name;
    this.appFmGroupId = id;
  }
  getGroup() {
   debugger
    this.fmGroupService
      .getList({
        ...this.filtersGroup,
      })
      .subscribe((item) => (this.groupItem = item));
  }
  getDysExtraDataDataInfo() {
    this.filters.fmDirectoryDescriptorId =
      this.directoryId == null ? this.pDirectoryId : this.directoryId;
    this.filters.relationKey = this.relationKey;
    //***************************** INFO İÇİN YAPILAN **************************************** */
    const getDataInfos = (query: ABP.PageQueryParams) =>
      this.extraInfoService.getList({
        ...query,
        ...this.filtersExtra,
        filterText: query.filter,
        veriTipi: undefined,
        kaynak: this.paramobj.kaynak,
        tanim: undefined,
      });
    const setDataInfos = (list: PagedResultDto<AppFmExtraDataInfoDto>) => (
      (this.dataInfo = list)
    );
    this.list.hookToQuery(getDataInfos).subscribe(setDataInfos);
  }
  getDysExtraData() {
    this.filters.fmDirectoryDescriptorId =
      this.directoryId == null ? this.pDirectoryId : this.directoryId;
    this.filters.relationKey = this.relationKey;
    //***************************** NAVİGATİONPROPERTY İÇİN YAPILAN **************************************** */
    const getDatas = (query: ABP.PageQueryParams) =>
      this.service.GetExtraDataAndExtraDataInfoToParamCode({
        ...query,
        ...this.filtersExtraDataForParamCode,
        filterText: query.filter,
        relationId: this.relationKey,
        veriTipi: undefined,
        kaynak: this.paramobj.kaynak,
        tanim: undefined,
      });

    const setDatas = (list: PagedResultDto<AppFmExtraDataWithNavigationPropertiesDto>) =>
      (this.dataExtraDataForParamCode = list,
        this.addControlForExtraData1(list)
        );
    this.list.hookToQuery(getDatas).subscribe(setDatas);
  }
  getDysMaster() {
    this.filters.fmDirectoryDescriptorId =
      this.directoryId == null ? this.pDirectoryId : this.directoryId;
    this.filters.relationKey = this.relationKey;
    //****************************** MASTER İÇİN YAPILAN *********************************** */
    const getData = (query: ABP.PageQueryParams) =>
      this.dysMasterService.getList({
        ...query,
        ...this.filters,
        filterText: query.filter,
        relationId: this.relationKey,
      });
    const setData = (list: PagedResultDto<DysMasterDto>) => (
      (this.dataDysMaster = list),
      this.setvalueToMissingFile(this.dataDysMaster.items)
    );
    this.list.hookToQuery(getData).subscribe(setData);
  }
  buildForm() {
    const {
      relationKey,
      relationType,
      tags,
      name,
      mimeType,
      content,
      size,
      description,
      creator,
      creationTime,
      appFmGroupID,
      missingFile,
      documentDate,
      documentValdtyDate,
      validityDateWarningDay,
      kaynak,
      appFmExtraDataDtos,
      yetkili,
    } = this.selected || {};
    this.form = this.fb.group({
      relationKey: [this.relationKey],
      relationType: [this.relationType ?? null, [Validators.required, Validators.maxLength(256)]],
      tags: [tags ?? null, [Validators.required, Validators.maxLength(256)]],
      name: [name ?? null, [Validators.required]],
      mimeType: [mimeType ?? null, [Validators.required]],
      content: [content ?? null, [Validators.required]],
      size: [size ?? null, [Validators.required]],
      masterDirectory: [
        this.masterDirectory ?? null,
        [Validators.required, Validators.maxLength(256)],
      ],
      subDirectory: [this.subDirectory ?? null, [Validators.required, Validators.maxLength(256)]],
      fmDirectoryDescriptorId: [this.directoryId ?? '00000000-0000-0000-0000-000000000000'],
      description: [description ?? null, []],
      appFmGroupID: [appFmGroupID ?? null],
      creator: [creator ?? null],
      creationTime: [creationTime ?? null],
      missingFile: [missingFile ?? false],
      yetkili: [missingFile ?? false],
      documentDate: [documentDate ?? null],
      documentValdtyDate: [documentValdtyDate ?? null],
      validityDateWarningDay: [validityDateWarningDay ?? null,[Validators.required]],
      kaynak: [missingFile ?? null],
      appFmExtraDataDtos: this.fb.array([this.createExtraDataforControl1("","","")])
    });
  }
  sayisal?: number = 0;
  setvalueToMissingFile(gelenArray: any[]) {
    gelenArray.forEach((x) => {
      this.form.controls.missingFile.setValue(x.missingFile);
    });
  }
  addControlForExtraData1(data:any) {
    if (this.ExtraDataforControl.length > 0) this.ExtraDataforControl.clear();
    for (let i = 0; i < this.paramobj.values.length; i++) {
    if(this.dataExtraDataForParamCode.totalCount>0){
      console.log("this.dataExtraDataForParamCode",this.dataExtraDataForParamCode)
      this.addExtraDataforControl1(
        this.dataExtraDataForParamCode.items[i].appFmExtraData.appFmExtraDataInfoId,
        null,
        this.dataExtraDataForParamCode.items[i].appFmExtraData.value
        );
    }else {
    this.addExtraDataforControl1(
        null,
        this.paramobj.values[i].paramCode.toString(),
        this.paramobj.values[i].value.toString()
        );
         }
      }
  }
  addExtraDataforControl1(id: any,paramCode:any ,value: any) {
    this.ExtraDataforControl.push(this.createExtraDataforControl1(id,paramCode ,value));
  }
  createExtraDataforControl1(id: any,paramCode:any ,value: any): FormGroup {
    return this.fb.group({
      appFmExtraDataInfoId: [id],//Validators.required sildim
      paramCode: [paramCode],
      value: [value, Validators.required],
    });
  }
  //Açılabilir çalışmaz ise
  // addControlForExtraData() {
  //   this.ExtraDataforControl.clear();
  //   this.paramobj.values.forEach((x) => {
  //     this.addExtraDataforControl();
  //   });
  // }
  // addExtraDataforControl() {
  //   this.ExtraDataforControl.push(this.createExtraDataforControl());
  // }
  // createExtraDataforControl(): FormGroup {
  //   return this.fb.group({
  //     appFmExtraDataInfoId: [null],
  //     value: [null, Validators.required],
  //   });
  // }
  get ExtraDataforControl(): FormArray {
    return <FormArray>this.form.get('appFmExtraDataDtos');
  }
  submitForm() {
  //  debugger;
  //console.log(this.form)
  var formObject = Object.assign({}, this.form.value);
  formObject.fmFileDescriptorId = this.directoryId ?? '00000000-0000-0000-0000-000000000000';
  formObject.description = this.fileDescription.nativeElement.value;
  formObject.appFmGroupID = this.appFmGroupId;
  formObject.kaynak=this.paramobj.kaynak;
  const request = this.service.upload(formObject);
  this.isModalBusy = true;
  request.subscribe((responseItem) => {
    if (!this.directoryId && responseItem.directoryId) {
      this.directoryId = responseItem.directoryId;
      this.pDirectoryId = this.directoryId;
    }
    this.isDataExistInList.emit(true);
    this.fmDirectoryDescriptorId.emit(responseItem.directoryId);
    this.getContentsOfDirectory();
    this.fileDescription.nativeElement.value = '';
    this.myFileInput.nativeElement.value = '';
    this.form.patchValue({
      documentDate: '',
      documentValdtyDate:'',
      validityDateWarningDay:'',
      appFmGroupID:''
    });
  });
  }
  hideForm() {
    this.isModalOpen = false;
    this.form.reset();
  }
  handleFileInput(files: FileList) {
    //debugger;
    this.fileToUpload = files.item(0);
    var reader = new FileReader();
    reader.onloadend = (result: any) => {
      //debugger;
      this.fileBase64String = btoa(result.currentTarget.result);
      this.form.value.content = this.fileBase64String;
      this.submitEnabled = true;
    };
    reader.readAsBinaryString(this.fileToUpload);
  }
  reader = new FileReader();
  sayac: number = 0;
  dataExtraInfo: PagedResultDto<AppFmExtraDataInfoDto> = {
    items: [],
    totalCount: 0,
  };
  deneme?: string[] = [];
  UploadFile() {
    if (!this.fileToUpload) {
      this.canInsert = false;
    }
    //debugger;
    this.form.value.mimeType = this.fileToUpload.type;
    this.form.value.name = this.fileToUpload.name;
    this.form.value.size = this.fileToUpload.size;
    this.form.value.masterDirectory = this.masterDirectory;
    this.form.value.subDirectory = this.subDirectory;
    if (this.form.value.content == null) this.form.value.content = this.fileBase64String;
    this.submitForm();
  }
  fileBase64String;
  dosyaEkle = (e: any) => {
    // //debugger;
    if (e) {
      const reader = new FileReader();
      this.fileBase64String = '';
      reader.onloadend = (result: any) => {
        this.fileBase64String = btoa(result.currentTarget.result);
      };
      reader.readAsBinaryString(this.fileToUpload);
    }
  };

  Download(record: FmRelationDto) {
    //debugger;
    if (record.yetkili == true) {
      let downloadFileInfo = this.service.getFileInfo(record.id);
      downloadFileInfo.subscribe((inf) => {
        this.mimeType = inf.mimeType;
        this.fileName = inf.name;
        //debugger;
        this.service.getFile(record.id).subscribe((d) => {
          const padding = '='.repeat((4 - (d.length % 4)) % 4);
          const base64 = (d + padding).replace(/-/g, '+').replace(/_/g, '/');

          const rawData = window.atob(base64);
          const outputArray = new Uint8Array(rawData.length);

          for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
          }
          this.blob = new Blob([outputArray], { type:  this.mimeType });
          //debugger;
          var objectUrl = URL.createObjectURL(this.blob);
          const downloadLink = document.createElement('a');
downloadLink.href = URL.createObjectURL(this.blob);
downloadLink.download = this.fileName; // İndirilecek dosya adını belirtin
downloadLink.click();
        });
      });
    } else {
      this.confirmation.error(
        record.appFmGroupID+" " +
        'Dökümanları ile ilgili işlem yapabilmek için yetki grubuna ekleme yapmalısınız','::DysFile',
        this.options
      );
    }
  }

  Delete(record: FmRelationDto) {
    //debugger
    if (record.yetkili == true) {
      this.confirmation
        .warn('::DeleteConfirmationMessage', '', { messageLocalizationParams: [] })
        .pipe(
          filter((status) => status === Confirmation.Status.confirm),
          switchMap(() => this.service.deleteFmRelation(record.fmRelationID)),
          switchMap(() => this.service.delete(record.id))
        )
        .subscribe(this.list.get);
    } else {
      this.confirmation.error(
        record.appFmGroupID+" " +
          'Dökümanları ile ilgili işlem yapabilmek için yetki grubuna ekleme yapmalısınız','::DysFile',
        this.options
      );
    }
  }
  ShowFile(record: FmRelationDto) {
    //debugger;
    if (record.yetkili == true) {
      let downloadFileInfo = this.service.getFileInfo(record.id);
      downloadFileInfo.subscribe((inf) => {
        this.mimeType = inf.mimeType;
        this.fileName = inf.name;
        //debugger;
        this.service.getFile(record.id).subscribe((d) => {
          const padding = '='.repeat((4 - (d.length % 4)) % 4);
          const base64 = (d + padding).replace(/-/g, '+').replace(/_/g, '/');
          const rawData = window.atob(base64);
          const outputArray = new Uint8Array(rawData.length);
          for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
          }
          this.blob = new Blob([outputArray], { type: this.mimeType });
          const objectUrl = URL.createObjectURL(this.blob);
          const newWindow = window.open();
          newWindow.document.write(`
            <html>
              <head>
                <meta charset="UTF-8">
                <title>Dosya Görüntüleme</title>
              </head>
              <body>
                <embed src="${objectUrl}" width="100%" height="100%" type="${this.mimeType}">
              </body>
            </html>
          `);
        });
      });
    } else {
      this.confirmation.error(
        record.appFmGroupID+" " +
          'Dökümanları ile ilgili işlem yapabilmek için yetki grubuna ekleme yapmalısınız','::DysFile',
        this.options
      );
    }
  }
  options: Partial<Confirmation.Options> = {
    hideCancelBtn: true,
    hideYesBtn: false,
    dismissible: false,
    cancelText: 'Close',
    yesText: 'Tamam',
    messageLocalizationParams: ['::ShowConfirmationMessage'],
    titleLocalizationParams: ['::DysFile'],
    // Custom icon ayarlaması yapabilirsin
    // icon: 'fa fa-exclamation-triangle', // or
    // iconTemplate : '<img src="custom-image-path.jpg" alt=""/>'
  };
}
function forEach(arg0: (i: any) => void) {
  throw new Error('Function not implemented.');
}
