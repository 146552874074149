import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const QUALITY_QUALITY_ANALYSIS_DETAILS_QUALITY_ANALYSIS_DETAIL_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/quality-analysis-details',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:QualityAnalysisDetails',
        layout: eLayoutType.application,
        requiredPolicy: 'AbpView.QualityAnalysisDetails',
      },
    ]);
  };
}
