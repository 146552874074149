import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_ANALYSIS_LAB_SUB_ANALYSIS_LOGS_ANALYSIS_LAB_SUB_ANALYSIS_LOG_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/analysis-lab-sub-analysis-logs',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:AnalysisLabSubAnalysisLogs',
        layout: eLayoutType.application,
        requiredPolicy: 'Laboratory.AnalysisLabSubAnalysisLogs',
        parentName:"::Menu:LogTabloları",
      },
    ]); 
  };
}
