import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const QUALITY_STOCK_ANALYSIS_DETAIL_INSTRUCTIONS_STOCK_ANALYSIS_DETAIL_INSTRUCTION_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/stock-analysis-detail-instructions',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:StockAnalysisDetailInstructions',
        layout: eLayoutType.application,
        requiredPolicy: 'AbpView.StockAnalysisDetailInstructions',
      },
    ]);
  };
}
