import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const DEVICE_CALIBRATIONS_DEVICE_CALIBRATION_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    // routes.add([
    //   {
    //     path: '/device-calibrations',
    //     iconClass: 'fas fa-file-alt',
    //     parentName:'::Menu:Laboratory',
    //     name: '::Laboratory:DeviceCalibrations2',
    //     layout: eLayoutType.application,
    //     requiredPolicy: 'Laboratory.DeviceCalibrations',
    //   },
    // ]);
  };
}
