import {  ConfigStateService, LocalizationService, PermissionService } from '@abp/ng.core';
import { Component, OnInit } from '@angular/core';
import { bpmInformation } from 'src/environments/environment';


@Component({
  selector: 'app-root',
  template: `
    <abp-loader-bar></abp-loader-bar>
    <abp-dynamic-layout></abp-dynamic-layout>
  `,
})
export class AppComponent implements OnInit {
  constructor(private config: ConfigStateService, private permissionService: PermissionService) {

    var canUseBPM = permissionService.getGrantedPolicy('Dashboard.VeraSurecleri'); //bpm süreçlerine yetkisi varsa,bpm den token almak için yönlenecek
    if (bpmInformation.useBpm && canUseBPM) {
      const currentUser = this.config.getOne('currentUser');

      // console.log('Current User :' + currentUser);
      // console.log('Current User Name :' + currentUser.email);
      if (currentUser.email !== null) {
        var index = currentUser.email.indexOf('@');
        // console.log('Current User ındex :' + index);
        if (index != -1) {
          const email = currentUser.email.substring(0, index);
          // console.log('Current User substring :' + email);

          var loginRequest = new XMLHttpRequest();

          // Asynchronous HTTP POST call to login API
          loginRequest.open('POST', bpmInformation.baseUrl + '/bonita/loginservice', true);//bonitanın login olmaya çalışıyor

          // Content-Type for the login operation
          loginRequest.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
          // To store cookie
          loginRequest.withCredentials = true;

          // Handle asynchronous call response
          loginRequest.onreadystatechange = function () {
            if (loginRequest.readyState === XMLHttpRequest.DONE) {

              // HTTP call to get user session information
              var sessionInfoRequest = new XMLHttpRequest();

              sessionInfoRequest.open(
                'GET',
                bpmInformation.baseUrl + '/bonita/API/system/session/unusedId/1',
                true
              );

              sessionInfoRequest.withCredentials = true;
              // Handle asynchronous call to session API response
              sessionInfoRequest.onreadystatechange = function () {
                if (sessionInfoRequest.readyState === XMLHttpRequest.DONE) {
                  console.log(
                    'Get user session information response HTTP status: ' +
                      sessionInfoRequest.status
                  );
                  var xBonitaAPIToken = sessionInfoRequest.getResponseHeader('X-Bonita-API-Token');
                  console.log(
                    'X-Bonita-API-Token from the HTTP header of session API response: ' +
                      xBonitaAPIToken
                  );
                }
              };

              sessionInfoRequest.send();
            }
          };

          loginRequest.send('username=' + email + '&password=Tekno-900*!&redirect=false');
        }
      }
    }


  }
  ngOnInit(): void {

  }

}
