import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_DEVICE_DEFINATIONS_DEVICE_DEFINATION_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/device-definations',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:DeviceDefinations',
        layout: eLayoutType.application,
        requiredPolicy: 'Laboratory.DeviceDefinations',
        parentName:'::Menu:LabrotoryDefinitions'
      },
    ]);
  };
}
