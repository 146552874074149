import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const QUALITY_STOCK_ANALYSES_STOCK_ANALYSIS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/stock-analyses',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:StockAnalyses',
        layout: eLayoutType.application,
        requiredPolicy: 'QualityAssurance.StockAnalyses',
        parentName:'::Menu:QualityAssurance'
      },
    ]);
  };
}
