import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_LAB_ORDER_MASTERS_LAB_ORDER_MASTER_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/lab-order-masters',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:LabOrderMasters',
        layout: eLayoutType.application,
        requiredPolicy: 'Laboratory.LabOrderMasters',
        parentName:"::Menu:WorkOrderProcedures",
      },
    ]);
  };
}
