import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const SUPPLIEREVALUATION_VIEWTIERAUTHORIZATION_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/view-tier-authorization',
        iconClass: 'fas fa-balance-scale',
        name: '::Menu:ViewTierAuthorization',
        layout: eLayoutType.application,
        requiredPolicy: 'Laboratory.LabOrderMasters',
        parentName:'::Menu:Laboratory',
      },
    ]);
  };
}