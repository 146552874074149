import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_LAB_ORDER_SUB_ANALYSES_LAB_ORDER_SUB_ANALYSIS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    //Serdar routes.add([
    //   {
    //     path: '/lab-order-sub-analyses',
    //     iconClass: 'fas fa-file-alt',
    //     name: '::Menu:LabOrderSubAnalyses',
    //     layout: eLayoutType.application,
    //     requiredPolicy: 'Laboratory.LabOrderSubAnalyses',
    //     parentName:"::Menu:AnalysisProcesses",
    //   },
    // ]);
  };
}
