import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const SUPPLIEREVALUATION_APPROVAL_TIER_LOGS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/approval-tier-logs',
        iconClass: 'fas fa-balance-scale',
        name: '::TDE:ApprovalTierLogs:HeaderText',
        layout: eLayoutType.application,
        requiredPolicy: 'Tde.ApprovalTierLog',
        parentName:'::TDE:Menu:Definitions'
      },
    ]);
  };
}