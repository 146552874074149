import { AuthGuard, PermissionGuard } from '@abp/ng.core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  {
    path: 'identity',
    loadChildren: () =>
      import('./identity-extended/identity-extended.module')
        .then(m => m.IdentityExtendedModule),
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [AuthGuard, PermissionGuard],
    data: {
      requiredPolicy: 'AbpView.Dashboard.Host || AbpAccount.SettingManagement',
    },
  },
  {
    path: 'identity',
    loadChildren: () => import('@volo/abp.ng.identity').then((m) => m.IdentityModule.forLazy()),
  },
  {
    path: 'account',
    loadChildren: () =>
      import('@volo/abp.ng.account/public').then((m) => m.AccountPublicModule.forLazy()),
  },
  {
    path: 'language-management',
    loadChildren: () =>
      import('@volo/abp.ng.language-management').then((m) => m.LanguageManagementModule.forLazy()),
  },
  {
    path: 'saas',
    loadChildren: () => import('@volo/abp.ng.saas').then((m) => m.SaasModule.forLazy()),
  },
  {
    path: 'audit-logs',
    loadChildren: () =>
      import('@volo/abp.ng.audit-logging').then((m) => m.AuditLoggingModule.forLazy()),
  },
  {
    path: 'identity-server',
    loadChildren: () =>
      import('@volo/abp.ng.identity-server').then((m) => m.IdentityServerModule.forLazy()),
  },
  {
    path: 'text-template-management',
    loadChildren: () =>
      import('@volo/abp.ng.text-template-management').then((m) =>
        m.TextTemplateManagementModule.forLazy()
      ),
  },
  {
    path: 'setting-management',
    loadChildren: () =>
      import('@abp/ng.setting-management').then((m) => m.SettingManagementModule.forLazy()),
  },
  {
    path: 'file-management',
    loadChildren: () =>
      import('@volo/abp.ng.file-management').then((m) => m.FileManagementModule.forLazy()),
  },
  {
    path: 'tekno-file-upload',
    loadChildren: () =>
      import('./tekno-file-upload/providers/tekno-file-upload.module').then(
        (m) => m.TeknoFileUploadModule
      ),
  },
  {
    path: 'chat',
    loadChildren: () => import('@volo/abp.ng.chat').then((m) => m.ChatModule.forLazy()),
  },
  {
    path: 'printers',
    loadChildren: () =>
      import('./isletme-bakim/departman-printers/departman-printer/departman-printer.module').then(
        (m) => m.DepartmanPrinterModule
      ),
  },
  {
    path: 'stok-test',
    loadChildren: () =>
      import('./test-component/isletme-bakim/stok-test/stok-test.module').then(
        (m) => m.StokTestModule
      ),
  },
  {
    path: 'stok-birim-test',
    loadChildren: () =>
      import('./test-component/isletme-bakim/stok-birim/stok-birim.module').then(
        (m) => m.StokBirimModule
      ),
  },
  {
    path: 'stok-tip-test',
    loadChildren: () =>
      import('./test-component/isletme-bakim/stok-tip/stok-tip.module').then(
        (m) => m.StokTipModule
      ),
  },
  {
    path: 'bakim-emri-masters',
    loadChildren: () =>
      import('./isletme-bakim/bakim-emri-masters/bakim-emri-master/bakim-emri-master.module').then(
        (m) => m.BakimEmriMasterModule
      ),
  },
  {
    path: 'bakim-emri-stoks',
    loadChildren: () =>
      import('./isletme-bakim/bakim-emri-stok/bakim-emri-stok/bakim-emri-stok.module').then(
        (m) => m.BakimEmriStokModule
      ),
  },

  {
    path: 'stok-tips',
    loadChildren: () =>
      import('./isletme-bakim/stok-tips/stok-tip/stok-tip.module').then((m) => m.StokTipModule),
  },
  {
    path: 'stok-birims',
    loadChildren: () =>
      import('./isletme-bakim/stok-birims/stok-birim/stok-birim.module').then(
        (m) => m.StokBirimModule
      ),
  },
  {
    path: 'stoks',
    loadChildren: () => import('./isletme-bakim/stoks/stok/stok.module').then((m) => m.StokModule),
  },
  {
    path: 'makina-tips',
    loadChildren: () =>
      import('./isletme-bakim/makina-tips/makina-tip/makina-tip.module').then(
        (m) => m.MakinaTipModule
      ),
  },
  {
    path: 'departmen',
    loadChildren: () =>
      import('./isletme-bakim/departmen/departman/departman.module').then((m) => m.DepartmanModule),
  },
  {
    path: 'vardiyas',
    loadChildren: () =>
      import('./isletme-bakim/vardiyas/vardiya/vardiya.module').then((m) => m.VardiyaModule),
  },
  {
    path: 'makinas',
    loadChildren: () =>
      import('./isletme-bakim/makinas/makina/makina.module').then((m) => m.MakinaModule),
  },
  {
    path: 'isemri-amacis',
    loadChildren: () =>
      import('./isletme-bakim/is-emri-amacis/is-emri-amaci/is-emri-amaci.module').then(
        (m) => m.IsEmriAmaciModule
      ),
  },
  {
    path: 'personels',
    loadChildren: () =>
      import('./isletme-bakim/personels/personel/personel.module').then((m) => m.PersonelModule),
  },
  {
    path: 'ariza-talebis',
    loadChildren: () =>
      import('./isletme-bakim/ariza-talebis/ariza-talebi/ariza-talebi.module').then(
        (m) => m.ArizaTalebiModule
      ),
  },
  {
    path: 'bakim-ariza-tips',
    loadChildren: () =>
      import('./isletme-bakim/bakim-ariza-tips/bakim-ariza-tip/bakim-ariza-tip.module').then(
        (m) => m.BakimArizaTipModule
      ),
  },
  {
    path: 'bakim-emri-personels',
    loadChildren: () =>
      import(
        './isletme-bakim/bakim-emri-personels/bakim-emri-personel/bakim-emri-personel.module'
      ).then((m) => m.BakimEmriPersonelModule),
  },

  {
    path: 'bakim-emri-personel-pivot-report',
    loadChildren: () =>
      import(
        './teknosol-components/isletme-bakim/reports/bakim-emri-personel/bakim-emri-personel-pivot-report.module'
      ).then((m) => m.BakimEmriPersonelPivotReportModule),
  },
  {
    path: 'stok-tips',
    loadChildren: () =>
      import('./isletme-bakim/stok-tips/stok-tip/stok-tip.module').then((m) => m.StokTipModule),
  },

  {
    path: 'ariza-talebi-report',
    loadChildren: () =>
      import(
        './teknosol-components/isletme-bakim/reports/ariza-talebi/ariza-talebi-report.module'
      ).then((m) => m.ArizaTalebiReportModule),
  },
  {
    path: 'currencies',
    loadChildren: () =>
      import('./accounting/currencies/currency/currency.module').then((m) => m.CurrencyModule),
  },
  {
    path: 'analysis-result-sets',
    loadChildren: () =>
      import('./quality/analysis-result-sets/analysis-result-set/analysis-result-set.module').then(
        (m) => m.AnalysisResultSetModule
      ),
  },
  {
    path: 'analysis-result-set-value-categories',
    loadChildren: () =>
      import(
        './quality/analysis-result-set-value-categories/analysis-result-set-value-category/analysis-result-set-value-category.module'
      ).then((m) => m.AnalysisResultSetValueCategoryModule),
  },
  {
    path: 'analysis-result-set-values',
    loadChildren: () =>
      import(
        './quality/analysis-result-set-values/analysis-result-set-value/analysis-result-set-value.module'
      ).then((m) => m.AnalysisResultSetValueModule),
  },
  {
    path: 'production-batch-sizes',
    loadChildren: () =>
      import(
        './quality/production-batch-sizes/production-batch-size/production-batch-size.module'
      ).then((m) => m.ProductionBatchSizeModule),
  },
  {
    path: 'acceptable-qualification-levels',
    loadChildren: () =>
      import(
        './quality/acceptable-qualification-levels/acceptable-qualification-level/acceptable-qualification-level.module'
      ).then((m) => m.AcceptableQualificationLevelModule),
  },
  {
    path: 'analyses',
    loadChildren: () =>
      import('./quality/analyses/analysis/analysis.module').then((m) => m.AnalysisModule),
  },

  {
    path: 'inspection-levels',
    loadChildren: () =>
      import('./quality/inspection-levels/inspection-level/inspection-level.module').then(
        (m) => m.InspectionLevelModule
      ),
  },
  {
    path: 'stock-analyses',
    loadChildren: () =>
      import('./quality/stock-analyses/stock-analysis/stock-analysis.module').then(
        (m) => m.StockAnalysisModule
      ),
  },
  {
    path: 'devexpress-report-viewer',
    loadChildren: () => import('./devexpress-report/viewer.module').then((m) => m.ViewerModule),
  },
  {
    path: 'devexpress-report-designer',
    loadChildren: () =>
      import('./devexpress-report/designer/designer.module').then((m) => m.DesignerModule),
  },
  {
    path: 'regions',
    loadChildren: () => import('./common/regions/region/region.module').then((m) => m.RegionModule),
  },
  {
    path: 'cities',
    loadChildren: () => import('./common/cities/city/city.module').then((m) => m.CityModule),
  },
  {
    path: 'banks',
    loadChildren: () => import('./banking/banks/bank/bank.module').then((m) => m.BankModule),
  },
  {
    path: 'branches',
    loadChildren: () =>
      import('./banking/branches/branch/branch.module').then((m) => m.BranchModule),
  },
  {
    path: 'banking-tenants',
    loadChildren: () =>
      import('./babil/banking-tenants/banking-tenant/banking-tenant.module').then(
        (m) => m.BankingTenantModule
      ),
  },
  {
    path: 'bank-api-informations',
    loadChildren: () =>
      import('./babil/bank-api-informations/bank-api-information/bank-api-information.module').then(
        (m) => m.BankApiInformationModule
      ),
  },
  {
    path: 'bank-accounts',
    loadChildren: () =>
      import('./babil/bank-accounts/bank-account/bank-account.module').then(
        (m) => m.BankAccountModule
      ),
  },
  {
    path: 'bank-account-informations',
    loadChildren: () =>
      import(
        './babil/bank-account-informations/bank-account-information/bank-account-information.module'
      ).then((m) => m.BankAccountInformationModule),
  },
  {
    path: 'bank-accounts-definition',
    loadChildren: () =>
      import('./babil/bank-account-definitions/bank-account-definitions.module').then(
        (m) => m.BankAccountDefinitionModule
      ),
  },
  {
    path: 'user-bank-accounts',
    loadChildren: () =>
      import('./babil/user-bank-accounts/user-bank-account/user-bank-account.module').then(
        (m) => m.UserBankAccountModule
      ),
  },
  {
    path: 'bank-account-transactions',
    loadChildren: () =>
      import(
        './babil/bank-account-transactions/bank-account-transaction/bank-account-transaction.module'
      ).then((m) => m.BankAccountTransactionModule),
  },
  {
    path: 'stock-analysis-detail-instructions',
    loadChildren: () =>
      import(
        './quality/stock-analysis-detail-instructions/stock-analysis-detail-instruction/stock-analysis-detail-instruction.module'
      ).then((m) => m.StockAnalysisDetailInstructionModule),
  },
  {
    path: 'stock-groups',
    loadChildren: () =>
      import('./isletme-bakim/stock-groups/stock-group/stock-group.module').then(
        (m) => m.StockGroupModule
      ),
  },
  {
    path: 'equipments',
    loadChildren: () =>
      import('./quality/equipments/equipment/equipment.module').then((m) => m.EquipmentModule),
  },
  {
    path: 'stock-analysis-details',
    loadChildren: () =>
      import(
        './quality/stock-analysis-details/stock-analysis-detail/stock-analysis-detail.module'
      ).then((m) => m.StockAnalysisDetailModule),
  },
  {
    path: 'quality-codes',
    loadChildren: () =>
      import('./stock/quality-codes/quality-code/quality-code.module').then(
        (m) => m.QualityCodeModule
      ),
  },
  {
    path: 'packing-types',
    loadChildren: () =>
      import('./stock/packing-types/packing-type/packing-type.module').then(
        (m) => m.PackingTypeModule
      ),
  },
  {
    path: 'routes',
    loadChildren: () => import('./work-flow/routes/route/route.module').then((m) => m.RouteModule),
  },
  {
    path: 'positions',
    loadChildren: () =>
      import('./work-flow/positions/position/position.module').then((m) => m.PositionModule),
  },
  {
    path: 'route-categories',
    loadChildren: () =>
      import('./work-flow/route-categories/route-category/route-category.module').then(
        (m) => m.RouteCategoryModule
      ),
  },
  {
    path: 'instructions',
    loadChildren: () =>
      import('./quality/instructions/instruction/instruction.module').then(
        (m) => m.InstructionModule
      ),
  },
  {
    path: 'app-roles',
    loadChildren: () =>
      import('./abp-modules-shared/app-roles/app-role/app-role.module').then(
        (m) => m.AppRoleModule
      ),
  },
  {
    path: 'route-start-roles',
    loadChildren: () =>
      import('./work-flow/route-start-roles/route-start-role/route-start-role.module').then(
        (m) => m.RouteStartRoleModule
      ),
  },
  {
    path: 'position-roles',
    loadChildren: () =>
      import('./work-flow/position-roles/position-role/position-role.module').then(
        (m) => m.PositionRoleModule
      ),
  },
  {
    path: 'transitions',
    loadChildren: () =>
      import('./work-flow/transitions/transition/transition.module').then(
        (m) => m.TransitionModule
      ),
  },
  {
    path: 'transition-triggers',
    loadChildren: () =>
      import('./work-flow/transition-triggers/transition-trigger/transition-trigger.module').then(
        (m) => m.TransitionTriggerModule
      ),
  },
  {
    path: 'transition-conditions',
    loadChildren: () =>
      import(
        './work-flow/transition-conditions/transition-condition/transition-condition.module'
      ).then((m) => m.TransitionConditionModule),
  },
  {
    path: 'transition-post-functions',
    loadChildren: () =>
      import(
        './work-flow/transition-post-functions/transition-post-function/transition-post-function.module'
      ).then((m) => m.TransitionPostFunctionModule),
  },
  {
    path: 'transition-validators',
    loadChildren: () =>
      import(
        './work-flow/transition-validators/transition-validator/transition-validator.module'
      ).then((m) => m.TransitionValidatorModule),
  },
  {
    path: 'app-users',
    loadChildren: () =>
      import('./abp-modules-shared/app-users/app-user/app-user.module').then(
        (m) => m.AppUserModule
      ),
  },
  {
    path: 'processes',
    loadChildren: () =>
      import('./work-flow/processes/process/process.module').then((m) => m.ProcessModule),
  },
  {
    path: 'process-values',
    loadChildren: () =>
      import('./work-flow/process-values/process-value/process-value.module').then(
        (m) => m.ProcessValueModule
      ),
  },
  {
    path: 'process-tasks',
    loadChildren: () =>
      import('./work-flow/process-tasks/process-task/process-task.module').then(
        (m) => m.ProcessTaskModule
      ),
  },
  {
    path: 'process-task-roles',
    loadChildren: () =>
      import('./work-flow/process-task-roles/process-task-role/process-task-role.module').then(
        (m) => m.ProcessTaskRoleModule
      ),
  },
  {
    path: 'quality-masters',
    loadChildren: () =>
      import('./quality/quality-masters/quality-master/quality-master.module').then(
        (m) => m.QualityMasterModule
      ),
  },
  {
    path: 'quality-master-vera-integrations',
    loadChildren: () =>
      import(
        './quality/quality-master-vera-integrations/quality-master-vera-integration/quality-master-vera-integration.module'
      ).then((m) => m.QualityMasterVeraIntegrationModule),
  },
  {
    path: 'quality-approvals',
    loadChildren: () =>
      import('./quality/quality-approvals/quality-approval/quality-approval.module').then(
        (m) => m.QualityApprovalModule
      ),
  },
  {
    path: 'quality-approval-stock-receipts',
    loadChildren: () =>
      import(
        './quality/quality-approval-stock-receipts/quality-approval-stock-receipt/quality-approval-stock-receipt.module'
      ).then((m) => m.QualityApprovalStockReceiptModule),
  },
  {
    path: 'quality-analyses',
    loadChildren: () =>
      import('./quality/quality-analyses/quality-analysis/quality-analysis.module').then(
        (m) => m.QualityAnalysisModule
      ),
  },
  {
    path: 'quality-analysis-details',
    loadChildren: () =>
      import(
        './quality/quality-analysis-details/quality-analysis-detail/quality-analysis-detail.module'
      ).then((m) => m.QualityAnalysisDetailModule),
  },
  {
    path: 'quality-analysis-detail-instructions',
    loadChildren: () =>
      import(
        './quality/quality-analysis-detail-instructions/quality-analysis-detail-instruction/quality-analysis-detail-instruction.module'
      ).then((m) => m.QualityAnalysisDetailInstructionModule),
  },
  {
    path: 'process-relations',
    loadChildren: () =>
      import('./work-flow/process-relations/process-relation/process-relation.module').then(
        (m) => m.ProcessRelationModule
      ),
  },
  {
    path: 'vera-process',
    loadChildren: () =>
      import('./vera-process/vera-process.module').then((m) => m.VeraProcessModule),
  },
  {
    path: 'vera-invoice',
    loadChildren: () =>
      import('./vera-invoice/vera-invoice.module').then((m) => m.VeraInvoiceModule),
  },
  {
    path: 'vera-receipt-advice',
    loadChildren: () =>
      import('./vera-receipt-advice/vera-receipt-advice.module').then(
        (m) => m.VeraReceiptAdviceModule
      ),
  },
  { path: 'route-versions', loadChildren: () => import('./work-flow/route-versions/route-version/route-version.module').then(m => m.RouteVersionModule) },
  {path: 'vera-ibsa-satis', loadChildren: () => import('./vera-ibsa/vara-ibsa.module').then(m => m.VeraIbsaModule)},
  { path: 'current-type-informations', loadChildren: () => import('./accounting/current-type-informations/current-type-information/current-type-information.module').then(m => m.CurrentTypeInformationModule) },
  { path: 'current-informations', loadChildren: () => import('./accounting/current-informations/current-information/current-information.module').then(m => m.CurrentInformationModule) },
  { path: 'book-masters', loadChildren: () => import('./laboratory/book-masters/book-master/book-master.module').then(m => m.BookMasterModule) },
  { path: 'book-records', loadChildren: () => import('./laboratory/book-records/book-record/book-record.module').then(m => m.BookRecordModule) },
  { path: 'device-definations', loadChildren: () => import('./laboratory/device-definations/device-defination/device-defination.module').then(m => m.DeviceDefinationModule) },
  { path: 'device-results', loadChildren: () => import('./laboratory/device-results/device-result/device-result.module').then(m => m.DeviceResultModule) },
  { path: 'exchange-rates', loadChildren: () => import('./banking/exchange-rates/exchange-rate/exchange-rate.module').then(m => m.ExchangeRateModule) },
  { path: 'book-master-role-auths', loadChildren: () => import('./laboratory/book-master-role-auths/book-master-role-auth/book-master-role-auth.module').then(m => m.BookMasterRoleAuthModule) },
  { path: 'book-record-logs', loadChildren: () => import('./laboratory/book-record-logs/book-record-log/book-record-log.module').then(m => m.BookRecordLogModule) },
  {path:'bpm/work-order-request',loadChildren:()=>import('./bpm/workorder-request/workorder-request.module').then(m=>m.WorkOrderRequestModule)},
  { path: 'bank-account-roles', loadChildren: () => import('./babil/bank-account-roles/bank-account-role/bank-account-role.module').then(m => m.BankAccountRoleModule) },
  { path:'bpm/raw-material',loadChildren:()=>import('./bpm/raw-material/raw-material.module').then(m=>m.RawMaterialModule)},
  { path:'signalr-messages',loadChildren:()=>import('./signalr/messages/messages.module').then(m=>m.MessagesModule)},
  {path:'bpm/formul',loadChildren:()=>import('./bpm/formul/formul.module').then(m=>m.FormulModule)},
  {path:'bpm/material-accept',loadChildren:()=>import('./bpm/material-accept/material-accept.module').then(m=>m.RawMaterialModule)},
  {path:'laboratory/analysis',loadChildren:()=>import('./test-component/analyses/analyses.module').then(m=>m.AnalysesModule)},
  {path:'test/bank-api-informations',loadChildren:()=>import('./test-component/babil/bank-api-informations/bank-api-information.module').then(m=>m.BankApiInformationModule)},
  { path: 'company-types', loadChildren: () => import('./babil/company-types/company-type/company-type.module').then(m => m.CompanyTypeModule) },
  { path: 'account-codes', loadChildren: () => import('./babil/account-codes/account-code/account-code.module').then(m => m.AccountCodeModule) },
  { path: 'budget-types', loadChildren: () => import('./babil/budget-types/budget-type/budget-type.module').then(m => m.BudgetTypeModule) },
  { path: 'companies', loadChildren: () => import('./babil/companies/company/company.module').then(m => m.CompanyModule) },
  { path: 'credit-types', loadChildren: () => import('./babil/credit-types/credit-type/credit-type.module').then(m => m.CreditTypeModule) },
  { path: 'company-bank-accounts', loadChildren: () => import('./babil/company-bank-accounts/company-bank-account/company-bank-account.module').then(m => m.CompanyBankAccountModule) },
  { path: 'match-rule-masters', loadChildren: () => import('./babil/match-rule-masters/teknosol-match-rule-master/match-rule-master.module').then(m => m.MatchRuleMasterModule) },
  { path: 'match-rule-filters', loadChildren: () => import('./babil/match-rule-filters/match-rule-filter/match-rule-filter.module').then(m => m.MatchRuleFilterModule) },
  { path: 'bank-analysis-codes', loadChildren: () => import('./babil/bank-analysis-codes/bank-analysis-code/bank-analysis-code.module').then(m => m.BankAnalysisCodeModule) },
  { path: 'bank-account-transaction-to-veras', loadChildren: () => import('./babil/bank-account-transaction-to-veras/bank-account-transaction-to-vera/bank-account-transaction-to-vera.module').then(m => m.BankAccountTransactionToVeraModule) },
  { path: 'match-rule-filter-values', loadChildren: () => import('./babil/match-rule-filter-values/match-rule-filter-value/match-rule-filter-value.module').then(m => m.MatchRuleFilterValueModule) },
  { path: 'credit-files', loadChildren: () => import('./babil/credit-files/credit-file/credit-file.module').then(m => m.CreditFileModule) },
  { path: 'kolay-ekstre/bank-account-transaction-to-veras', loadChildren: () => import('./teknosol-components/babil/bank-account-transaction-to-veras/bank-account-transaction-to-vera/bank-account-transaction-to-vera.module').then(m => m.BankAccountTransactionToVeraModule) },
  { path: 'departman-of-babils', loadChildren: () => import('./babil/departman-of-babils/departman-of-babil/departman-of-babil.module').then(m => m.DepartmanOfBabilModule) },
  { path: 'lab-stock-usage-exceptions', loadChildren: () => import('./laboratory/lab-stock-usage-exceptions/lab-stock-usage-exception/lab-stock-usage-exception.module').then(m => m.LabStockUsageExceptionModule) },
  { path: 'lab-sub-analyzes', loadChildren: () => import('./laboratory/lab-sub-analyzes/teknosol-lab-sub-analysis/lab-sub-analysis.module').then(m => m.LabSubAnalysisModule) },
  { path: 'analysis-lab-sub-analyzes', loadChildren: () => import('./laboratory/analysis-lab-sub-analyzes/analysis-lab-sub-analysis/analysis-lab-sub-analysis.module').then(m => m.AnalysisLabSubAnalysisModule) },
  { path: 'analysis-lab-sub-analyses', loadChildren: () => import('./laboratory/analysis-lab-sub-analyses/teknosol-analysis-lab-sub-analysis/analysis-lab-sub-analysis.module').then(m => m.AnalysisLabSubAnalysisModule) },
  { path: 'lab-order-masters', loadChildren: () => import('./laboratory/lab-order-masters/teknosol-lab-order-master/lab-order-master.module').then(m => m.LabOrderMasterModule) },
  { path: 'laboratory-units', loadChildren: () => import('./laboratory/laboratory-units/laboratory-unit/laboratory-unit.module').then(m => m.LaboratoryUnitModule) },
  { path: 'lab-order-categories', loadChildren: () => import('./laboratory/lab-order-categories/teknosol-lab-order-category/lab-order-category.module').then(m => m.LabOrderCategoryModule) },
  { path: 'lab-order-category-roles', loadChildren: () => import('./laboratory/lab-order-category-roles/lab-order-category-role/lab-order-category-role.module').then(m => m.LabOrderCategoryRoleModule) },
  { path: 'lab-order-category-stock-types', loadChildren: () => import('./laboratory/lab-order-category-stock-types/lab-order-category-stock-type/lab-order-category-stock-type.module').then(m => m.LabOrderCategoryStockTypeModule) },
  { path: 'lab-order-master-assigned-users', loadChildren: () => import('./laboratory/lab-order-master-assigned-users/lab-order-master-assigned-user/lab-order-master-assigned-user.module').then(m => m.LabOrderMasterAssignedUserModule) },
  { path: 'lab-sample-categories', loadChildren: () => import('./laboratory/lab-sample-categories/lab-sample-category/lab-sample-category.module').then(m => m.LabSampleCategoryModule) },
  { path: 'lab-sample-masters', loadChildren: () => import('./laboratory/lab-sample-masters/lab-sample-master/lab-sample-master.module').then(m => m.LabSampleMasterModule) },
  { path: 'lab-sample-point-categories', loadChildren: () => import('./laboratory/lab-sample-point-categories/lab-sample-point-category/lab-sample-point-category.module').then(m => m.LabSamplePointCategoryModule) },
  { path: 'lab-sample-points', loadChildren: () => import('./laboratory/lab-sample-points/teknosol-lab-sample-point/lab-sample-point.module').then(m => m.LabSamplePointModule) },
  { path: 'lab-sample-sub-points', loadChildren: () => import('./laboratory/lab-sample-sub-points/lab-sample-sub-point/lab-sample-sub-point.module').then(m => m.LabSampleSubPointModule) },
  { path: 'lab-sample-details', loadChildren: () => import('./laboratory/lab-sample-details/lab-sample-detail/lab-sample-detail.module').then(m => m.LabSampleDetailModule) },
  { path: 'lab-order-samples', loadChildren: () => import('./laboratory/lab-order-samples/lab-order-sample/lab-order-sample.module').then(m => m.LabOrderSampleModule) },
  { path: 'lab-order-log-books', loadChildren: () => import('./laboratory/lab-order-log-books/lab-order-log-book/lab-order-log-book.module').then(m => m.LabOrderLogBookModule) },
  { path: 'lab-order-analyses', loadChildren: () => import('./laboratory/lab-order-analyses/lab-order-analysis/lab-order-analysis.module').then(m => m.LabOrderAnalysisModule) },
  { path: 'lab-order-sub-analyses', loadChildren: () => import('./laboratory/lab-order-sub-analyses/lab-order-sub-analysis/lab-order-sub-analysis.module').then(m => m.LabOrderSubAnalysisModule) },
  { path: 'lab-order-sub-analysis-formulas', loadChildren: () => import('./laboratory/lab-order-sub-analysis-formulas/lab-order-sub-analysis-formula/lab-order-sub-analysis-formula.module').then(m => m.LabOrderSubAnalysisFormulaModule) },
  { path: 'lab-order-sub-analysis-results', loadChildren: () => import('./laboratory/lab-order-sub-analysis-results/lab-order-sub-analysis-result/lab-order-sub-analysis-result.module').then(m => m.LabOrderSubAnalysisResultModule) },
  { path: 'lab-order-sub-analysis-stock-usages', loadChildren: () => import('./laboratory/lab-order-sub-analysis-stock-usages/lab-order-sub-analysis-stock-usage/lab-order-sub-analysis-stock-usage.module').then(m => m.LabOrderSubAnalysisStockUsageModule) },
  { path: 'device-categories', loadChildren: () => import('./laboratory/device-categories/device-category/device-category.module').then(m => m.DeviceCategoryModule) },
  { path: 'lab-order-category-groups', loadChildren: () => import('./laboratory/lab-order-category-groups/lab-order-category-group/lab-order-category-group.module').then(m => m.LabOrderCategoryGroupModule) },
  { path: 'lab-samples', loadChildren: () => import('./laboratory/lab-samples/teknosol-lab-sample/lab-sample.module').then(m => m.LabSampleModule) },
  { path: 'laboratory-unit-logs', loadChildren: () => import('./laboratory/laboratory-unit-logs/laboratory-unit-log/laboratory-unit-log.module').then(m => m.LaboratoryUnitLogModule) },
  { path: 'analysis-lab-sub-analysis-logs', loadChildren: () => import('./laboratory/analysis-lab-sub-analysis-logs/analysis-lab-sub-analysis-log/analysis-lab-sub-analysis-log.module').then(m => m.AnalysisLabSubAnalysisLogModule) },
  { path: 'device-category-logs', loadChildren: () => import('./laboratory/device-category-logs/device-category-log/device-category-log.module').then(m => m.DeviceCategoryLogModule) },
  { path: 'device-defination-logs', loadChildren: () => import('./laboratory/device-defination-logs/device-defination-log/device-defination-log.module').then(m => m.DeviceDefinationLogModule) },
  { path: 'device-result-logs', loadChildren: () => import('./laboratory/device-result-logs/device-result-log/device-result-log.module').then(m => m.DeviceResultLogModule) },
  { path: 'lab-order-analysis-logs', loadChildren: () => import('./laboratory/lab-order-analysis-logs/lab-order-analysis-log/lab-order-analysis-log.module').then(m => m.LabOrderAnalysisLogModule) },
  { path: 'lab-order-category-group-logs', loadChildren: () => import('./laboratory/lab-order-category-group-logs/lab-order-category-group-log/lab-order-category-group-log.module').then(m => m.LabOrderCategoryGroupLogModule) },
  { path: 'lab-order-category-logs', loadChildren: () => import('./laboratory/lab-order-category-logs/lab-order-category-log/lab-order-category-log.module').then(m => m.LabOrderCategoryLogModule) },
  { path: 'lab-order-category-role-logs', loadChildren: () => import('./laboratory/lab-order-category-role-logs/lab-order-category-role-log/lab-order-category-role-log.module').then(m => m.LabOrderCategoryRoleLogModule) },
  { path: 'lab-order-category-stock-type-logs', loadChildren: () => import('./laboratory/lab-order-category-stock-type-logs/lab-order-category-stock-type-log/lab-order-category-stock-type-log.module').then(m => m.LabOrderCategoryStockTypeLogModule) },
  { path: 'lab-order-log-book-logs', loadChildren: () => import('./laboratory/lab-order-log-book-logs/lab-order-log-book-log/lab-order-log-book-log.module').then(m => m.LabOrderLogBookLogModule) },
  { path: 'lab-order-master-assigned-user-logs', loadChildren: () => import('./laboratory/lab-order-master-assigned-user-logs/lab-order-master-assigned-user-log/lab-order-master-assigned-user-log.module').then(m => m.LabOrderMasterAssignedUserLogModule) },
  { path: 'lab-order-master-logs', loadChildren: () => import('./laboratory/lab-order-master-logs/lab-order-master-log/lab-order-master-log.module').then(m => m.LabOrderMasterLogModule) },
  { path: 'lab-order-sample-logs', loadChildren: () => import('./laboratory/lab-order-sample-logs/lab-order-sample-log/lab-order-sample-log.module').then(m => m.LabOrderSampleLogModule) },
  { path: 'lab-order-sub-analysis-formula-logs', loadChildren: () => import('./laboratory/lab-order-sub-analysis-formula-logs/lab-order-sub-analysis-formula-log/lab-order-sub-analysis-formula-log.module').then(m => m.LabOrderSubAnalysisFormulaLogModule) },
  { path: 'lab-order-sub-analysis-logs', loadChildren: () => import('./laboratory/lab-order-sub-analysis-logs/lab-order-sub-analysis-log/lab-order-sub-analysis-log.module').then(m => m.LabOrderSubAnalysisLogModule) },
  { path: 'lab-order-sub-analysis-result-logs', loadChildren: () => import('./laboratory/lab-order-sub-analysis-result-logs/lab-order-sub-analysis-result-log/lab-order-sub-analysis-result-log.module').then(m => m.LabOrderSubAnalysisResultLogModule) },
  { path: 'lab-order-sub-analysis-stock-usage-logs', loadChildren: () => import('./laboratory/lab-order-sub-analysis-stock-usage-logs/lab-order-sub-analysis-stock-usage-log/lab-order-sub-analysis-stock-usage-log.module').then(m => m.LabOrderSubAnalysisStockUsageLogModule) },
  { path: 'lab-sample-logs', loadChildren: () => import('./laboratory/lab-sample-logs/lab-sample-log/lab-sample-log.module').then(m => m.LabSampleLogModule) },
  { path: 'lab-sample-point-logs', loadChildren: () => import('./laboratory/lab-sample-point-logs/lab-sample-point-log/lab-sample-point-log.module').then(m => m.LabSamplePointLogModule) },
  { path: 'lab-sample-sub-point-logs', loadChildren: () => import('./laboratory/lab-sample-sub-point-logs/lab-sample-sub-point-log/lab-sample-sub-point-log.module').then(m => m.LabSampleSubPointLogModule) },
  { path: 'lab-stock-usage-exception-logs', loadChildren: () => import('./laboratory/lab-stock-usage-exception-logs/lab-stock-usage-exception-log/lab-stock-usage-exception-log.module').then(m => m.LabStockUsageExceptionLogModule) },
  { path: 'lab-sub-analysis-logs', loadChildren: () => import('./laboratory/lab-sub-analysis-logs/lab-sub-analysis-log/lab-sub-analysis-log.module').then(m => m.LabSubAnalysisLogModule) },
  { path: 'check-bill-positions', loadChildren: () => import('./babil/check-bill-positions/check-bill-position/check-bill-position.module').then(m => m.CheckBillPositionModule) },
  { path: 'match-rule-filter-query-method-operations', loadChildren: () => import('./babil/match-rule-filter-query-method-operations/match-rule-filter-query-method-operation/match-rule-filter-query-method-operation.module').then(m => m.MatchRuleFilterQueryMethodOperationModule) },
  { path: 'bank-account-transaction-to-vera-logs', loadChildren: () => import('./babil/bank-account-transaction-to-vera-logs/bank-account-transaction-to-vera-log/bank-account-transaction-to-vera-log.module').then(m => m.BankAccountTransactionToVeraLogModule) },
  { path: 'lab-order-master-result-entry', loadChildren: () => import('./laboratory/lab-order-master-result-entry/lab-order-master-result-entry.module').then(m => m.LabOrderMasterResultEntryModule) },
  { path: 'device-calibration-and-log', loadChildren: () => import('./laboratory/device-calibration-and-log/device-calibration-and-log.module').then(m => m.DeviceCalibrationAndLogModule) },
  { path: 'match-rule-filter-values-controls', loadChildren: () => import('./babil/match-rule-filter-values-controls/teknosol-match-rule-filter-values-control/match-rule-filter-values-control.module').then(m => m.MatchRuleFilterValuesControlModule) },
  { path: 'device-calibrations', loadChildren: () => import('./device-calibrations/device-calibration/device-calibration.module').then(m => m.DeviceCalibrationModule) },
  { path: 'app-fm-extra-datas', loadChildren: () => import('./app-fm-extra-datas/app-fm-extra-data/app-fm-extra-data.module').then(m => m.AppFmExtraDataModule) },
  { path: 'app-fm-extra-data-infos', loadChildren: () => import('./app-fm-extra-data-infos/app-fm-extra-data-info/app-fm-extra-data-info.module').then(m => m.AppFmExtraDataInfoModule) },
  { path: 'app-fm-groups', loadChildren: () => import('./app-fm-groups/app-fm-group/app-fm-group.module').then(m => m.AppFmGroupModule) },
  { path: 'app-fm-authorities', loadChildren: () => import('./app-fm-authorities/app-fm-authority/app-fm-authority.module').then(m => m.AppFmAuthorityModule) },
  { path: 'tier-stock-relations', loadChildren: () => import('./supplier/tier-stock-relations/tier-stock-relation/tier-stock-relation.module').then(m => m.TierStockRelationModule) },
  { path: 'dys-masters', loadChildren: () => import('./dys-masters/dys-master/dys-master.module').then(m => m.DysMasterModule) },
  { path: 'tiers', loadChildren: () => import('./supplier/tiers/tier/tier.module').then(m => m.TierModule) },
  { path: 'group', loadChildren: () => import('./supplierEvaluation/group/group.module').then(m => m.GroupModule) },
  { path: 'approval-tiers', loadChildren: () => import('./supplierEvaluation/ApprovalTiers/ApprovalTiers.module').then(m => m.ApprovalTiersModule) },
  { path: 'tier-authorizations', loadChildren: () => import('./supplierEvaluation/tierAuthorizations/tierAuthorizations.module').then(m => m.TierAuthorizationsModule) },
  { path: 'company-infos', loadChildren: () => import('./supplierEvaluation/companyInfos/companyInfos.module').then(m => m.CompanyInfosModule) },
  { path: 'stock-infos', loadChildren: () => import('./supplierEvaluation/stockInfos/stockInfos.module').then(m => m.StockInfosModule) },
  { path: 'stock-file-information-list', loadChildren: () => import('./supplierEvaluation/StockFileInformationList/StockFileInformationList.module').then(m => m.StockFileInformationListModule) },
  { path: 'customer-file-information-list', loadChildren: () => import('./supplierEvaluation/customerFileInformationList/customerFileInformationList.module').then(m => m.CustomerFileInformationListModule) },
  { path: 'approval-tier-logs', loadChildren: () => import('./supplierEvaluation/approvalTierLogs/approvalTierLogs.module').then(m => m.ApprovalTierLogsModule) },
  { path: 'settings', loadChildren: () => import('./supplierEvaluation/ayarlar/ayarlar.module').then(m => m.AyarlarModule) },
  { path: 'teknosol', loadChildren: () => import('./supplierEvaluation/teknosol/teknosol.module').then(m => m.TeknosolModule) },
  { path: 'view-approval-tiers', loadChildren: () => import('./laboratory/view-approval-tiers/view-approval-tier/view-approval-tier.module').then(m => m.ViewApprovalTierModule) },
  { path: 'view-approval-tier-logs', loadChildren: () => import('./laboratory/view-approval-tier-logs/view-approval-tier-log/view-approval-tier-log.module').then(m => m.ViewApprovalTierLogModule) },
  { path: 'view-approval-tier-settings', loadChildren: () => import('./laboratory/view-approval-tier-settings/view-approval-tier-setting/view-approval-tier-setting.module').then(m => m.ViewApprovalTierSettingModule) },
  { path: 'approval-tier-logs', loadChildren: () => import('./supplierEvaluation/approvalTierLogs/approvalTierLogs.module').then(m => m.ApprovalTierLogsModule) },
  { path: 'view-tier-authorization', loadChildren: () => import('./laboratory/view-tier-authorizations/view-tier-authorization/view-tier-authorizations.module').then(m => m.ViewTierAuthorizationModule) },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {
  // deneme
}
