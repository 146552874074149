import type { AppFmGroupCreateDto, AppFmGroupDto, AppFmGroupUpdateDto, GetAppFmGroupsInput } from './models';
import { RestService } from '@abp/ng.core';
import type { PagedResultDto } from '@abp/ng.core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppFmGroupService {
  apiName = 'Default';

  create = (input: AppFmGroupCreateDto) =>
    this.restService.request<any, AppFmGroupDto>({
      method: 'POST',
      url: '/api/app/app-fm-groups',
      body: input,
    },
    { apiName: this.apiName });

  delete = (id: string) =>
    this.restService.request<any, void>({
      method: 'DELETE',
      url: `/api/app/app-fm-groups/${id}`,
    },
    { apiName: this.apiName });

  get = (id: string) =>
    this.restService.request<any, AppFmGroupDto>({
      method: 'GET',
      url: `/api/app/app-fm-groups/${id}`,
    },
    { apiName: this.apiName });

  getList = (input: GetAppFmGroupsInput) =>
    this.restService.request<any, PagedResultDto<AppFmGroupDto>>({
      method: 'GET',
      url: '/api/app/app-fm-groups',
      params: { filterText: input.filterText, code: input.code, name: input.name, parentId: input.parentId, sorting: input.sorting, skipCount: input.skipCount, maxResultCount: input.maxResultCount },
    },
    { apiName: this.apiName });

  update = (id: string, input: AppFmGroupUpdateDto) =>
    this.restService.request<any, AppFmGroupDto>({
      method: 'PUT',
      url: `/api/app/app-fm-groups/${id}`,
      body: input,
    },
    { apiName: this.apiName });

  constructor(private restService: RestService) {}
}
