import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const ISLETME_BAKIM_BAKIM_EMRI_MASTERS_BAKIM_EMRI_MASTER_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/bakim-emri-masters',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:BakimEmriMasters',
        layout: eLayoutType.application,
        parentName:'::Menu:IBYS:IbysIslemler',
        requiredPolicy: 'Ibys.BakimEmriMasters',
        order:2
      },
    ]);
  };
}
