import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const ISLETME_BAKIM_DEPARTMAN_PRINTERS_DEPARTMAN_PRINTER_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/printers',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:DepartmanPrinters',
        layout: eLayoutType.application,          
        requiredPolicy: 'Ibys.DepartmanPrinters',
       parentName:'::Menu:IBYS:ConstDefinition',    
      },
    ]);
  };
}
