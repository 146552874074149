import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const REPORT_GROUP_WITH_REPORT_FILES_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/report-group-with-report-files',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:ReportGroupWithReportFiles',
        layout: eLayoutType.application,
        requiredPolicy: 'Reporting.ReportGroups'
      },
    ]);
  };
}
