import { RoutesService, eLayoutType, LocalizationService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/',
        name: '::Menu:Home',
        iconClass: 'fas fa-home',
        order: 1,
        layout: eLayoutType.application,
      },
      {
        path: '',
        name: 'IBYS',
        iconClass: 'fas fa-car-battery',
        order: 3,
        layout: eLayoutType.application,
      },
      {
        path: '',
        name: '::Menu:IBYS:ConstDefinition',
        requiredPolicy: 'Ibys.IbysTanimlamalar',
        parentName: 'IBYS',
        order: 1,
        layout: eLayoutType.application,
      },
      {
        path: '',
        name: '::Menu:IBYS:IbysIslemler',
        layout: eLayoutType.application,
        requiredPolicy: 'Ibys.IbysIslemler',
        parentName: 'IBYS',
        order: 2,
      },

      {
        path: '',
        name: '::Menu:IBYS:Reports',
        layout: eLayoutType.application,
        requiredPolicy: 'Ibys.IbysReports',
        parentName: 'IBYS',
        order: 3,
      },
      {
        path: '',
        name: '::Menu:IBYS:Test',
        parentName: 'IBYS',
        layout: eLayoutType.application,
        requiredPolicy: 'Ibys.IbysTest',
        order: 4,
      },
      {
        path: '',
        name: '::Menu:Babil',
        order: 5,
        iconClass: 'fa fa-money',
      },
      {
        path: '',
        name: '::Menu:Babil:Definitions',
        order: 2,
        parentName: '::Menu:Babil',
        iconClass:'fas fa-hammer',
        requiredPolicy:'Babil.Definitions'
      },
      {
        path:'',
        name:'::TDE:Menu:HeaderText',
        order:11,
        iconClass:'fas fa-balance-scale'
      },
      {
        path: '',
        name: '::TDE:Menu:Definitions',
        // requiredPolicy: 'Tde.Definitions',
        parentName: '::TDE:Menu:HeaderText',
        order: 1,
        layout: eLayoutType.application,
      },
      {
        name: '::Menu:Accounting',
        path: '',
        order: 6,
        iconClass: 'fas fa-cash-register',
      },
      {
        name: '::Menu:Accounting:Definitions',
        order: 1,
        path: '',
        parentName: '::Menu:Accounting',
        requiredPolicy:'Accounting.Definitions'
      },
      {
        name: '::Menu:Common',
        path: '',
        order: 6,
        iconClass: 'fas fa-cogs',
      },
      {
        name: '::Menu:Common:Definitions',
        order: 1,
        path: '',
        parentName: '::Menu:Common',
        requiredPolicy:'Common.Definitions'
      },
      {
        name: '::Menu:Banking',
        path: '',
        order: 6,
        iconClass: 'fas fa-university',
      },
      {
        name: '::Menu:Banking:Definitions',
        order: 2,
        path: '',
        parentName: '::Menu:Banking',
        requiredPolicy:'Banking.Definitions'
      },

      {
        path: '',
        name: '::Menu:Entegrasyon',
        iconClass: 'fas fa-camera-retro',
        layout: eLayoutType.application,
      },

      {
        path: '',
        name: '::Menu:WorkFlow',
        iconClass: 'fas fa-microchip',
        layout: eLayoutType.application,
        order:7
      },{
        path:'',
        name:'::Menu:QualityManagement',
        iconClass:'fab fa-quora',
        layout:eLayoutType.application,
        order:8
      },  {
        name: '::Menu:Sales',
        path: '',
        order: 9,
        layout:eLayoutType.application,
        iconClass: 'fas fa-wallet',
      },{
        path:'',
        name:'::Menu:QualityManagement:Definition',
        parentName:'::Menu:QualityManagement',
        order:1,
        requiredPolicy:'Banking.Definitions'
      },{
        path:'',
        name:'::Menu:QualityAssurance',
        parentName:'::Menu:QualityManagement',
        order:2,
        requiredPolicy:'Banking.Definitions'
      },{
        path:'',
        name:'::Menu:Laboratory',
        order:10,
        requiredPolicy:'Laboratory.BookMasters',
        iconClass:'fas fa-syringe'
      },{
        path:'',
        name:'::Menu:VeraProcessWatch',
        order:11,
        iconClass:'fas fa-network-wired'
      },{
        path:'',
        name:'::Menu:Teknosol:TestComponent',
        requiredPolicy:'Banking.Definitions',
        order:12,
        iconClass:'fa fa-check'
      },

      {
        path:'',
        name:'::Menu:SamplingProcedures',
        parentName:'::Menu:Laboratory',
        order:4,
      },
      {
        path:'',
        name:'::Menu:AnalysisProcesses',
        parentName:'::Menu:Laboratory',
        order:1,
      },
      {

        path:'',
        name:'::Menu:WorkOrderProcedures',
        parentName:'::Menu:Laboratory',
        order:2,
      },
      {
        path:'',
        name:'::Menu:LabrotoryDefinitions',
        parentName:'::Menu:Laboratory',
        order:3,
      },
      {

        path:'',
        name:'::Menu:LogTabloları',
        parentName:'::Menu:Laboratory',
        order:5,
      },
    ]);
  };
}
