import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_LABORATORY_UNIT_LOGS_LABORATORY_UNIT_LOG_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/laboratory-unit-logs',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:LaboratoryUnitLogs',
        layout: eLayoutType.application,
        requiredPolicy: 'Laboratory.LaboratoryUnitLogs',
        parentName:"::Menu:LogTabloları",
      },
    ]);
  };
}
