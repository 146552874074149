// import { Environment } from '@abp/ng.core';
// import { BpmInformation } from '@proxy/shared/teknosol.model';
import { Environment } from '@abp/ng.core';
import { BpmInformation } from '../app/proxy/shared/teknosol.model';


//const baseUrl = 'https://viewmig.teknosol.com.tr:4499/';
//const baseUrl = 'http://localhost:4499';
//const baseUrl = 'https://localhost:44999';
//const baseUrl = 'https://frontend.firat.com:10002';
//const baseUrl = 'https://abpview.teknosol.com.tr';
const baseUrl = 'https://client.beytullah.net';
const bpmBaseUrl = 'https://bpm.teknosol.com.tr';



export const bpmInformation = {
  baseUrl: bpmBaseUrl, useBpm: true
} as BpmInformation;

export const environment = {
  production: false, localization: {
    defaultResourceName: 'AbpView'
  },

  application: {
    baseUrl,
    name: 'AbpView',
    logoUrl: 'assets/images/logo/boehler-logo.png'
  },
 
  oAuthConfig: {
   // issuer: 'https://demo.teknosol.com.tr:9999',
   // issuer: 'https://localhost:44377',
   // issuer:'http://localhost:9999',
  // issuer:'https://localhost:10000',
  //issuer:'https://backend.firat.com:10001',
  //issuer:'https://abpviewapi.teknosol.com.tr',
  issuer:'https://api.beytullah.net',
    redirectUri: baseUrl,
    clientId: 'AbpView_App',
  // clientId: 'WebGateway_Swagger',
    responseType: 'code',
    scope: 'offline_access AbpView',
   // scope: 'SupplierEvaluation WebGateway AbpView '
  },

  apis: {
    default: {
     // url: 'https://demo.teknosol.com.tr:9999',
    //  url: 'https://localhost:44377',
   // url: 'http://localhost:9999',
   // url: 'https://localhost:10000',
   // url: 'https://backend.firat.com:10001',
    url: 'https://api.beytullah.net',
      rootNamespace: 'AbpView'
    },
    supplierEvaluation: { url: 'https://gateway.teknosol.com.tr:5001/supplier-evaluation' }
  },

  // Chat: {
  //   // url: 'https://demo.teknosol.com.tr:9999',
  //   // signalRUrl: 'https://demo.teknosol.com.tr:9999'

  //   url: 'http://localhost:9999',
  //   signalRUrl: 'http://localhost:9999'
  // }
} as Environment;
