import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const QUALITY_ANALYSES_ANALYSIS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/analyses',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:Analyses',
        layout: eLayoutType.application,
        requiredPolicy: 'QualityManagement.Analyses',        
        parentName:"::Menu:AnalysisProcesses",
      },
    ]);
  };
}
