import { ListResultDto, RestService } from '@abp/ng.core'; 
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FmRelationDto } from './models';
 

@Injectable({
  providedIn: 'root',
})
export class TeknoFileUploadTeknosolService {
  apiName = 'Default';

  isUploadFile:any=false;
  isDeleteFile:any=false;
  isDownloadFile:any=false;
  isShowFile:any=false;
  // getYetkiliFmRelationWithGroup = () =>
  //   this.restService.request<any, FmRelationDto[]>(
  //     {
  //       method: 'GET',
  //       url: `/api/app/fm-relations/get-yetkili-fmRelation-with-group`,
  //       params: {}, 
  //     },
  //     { apiName: this.apiName }
  //   );
   
  constructor(private restService: RestService) {}
}
