import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const QUALITY_ANALYSES_TEST_ANALYSIS_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: 'laboratory/analysis',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:Analyses',
        layout: eLayoutType.application,  
        order:1,
        // parentName:'::Menu:Teknosol:TestComponent',
        parentName:"::Menu:AnalysisProcesses",
        
      },
    ]);
  };
}
