import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';


export const ISLETME_BAKIM_ISEMRI_AMACIS_ISEMRI_AMACI_ROUTE_PROVIDER = [
     { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
   ];
   
   function configureRoutes(routes: RoutesService) {
     return () => {
       routes.add([
         {
           path: '/isemri-amacis',
           iconClass: 'fas fa-file-alt',
           name: '::Menu:IsEmriAmacis',
           layout: eLayoutType.application,
           requiredPolicy: 'VeraIntegration.IsEmriAmacis',
           parentName:'::Menu:Entegrasyon'
         },
       ]);
     };
   }