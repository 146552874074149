import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const ACCOUNTING_CURRENCIES_CURRENCY_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/currencies',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:Currencies',
        layout: eLayoutType.application,
        requiredPolicy: 'Accounting.Currencies',
        parentName:'::Menu:Accounting:Definitions'        
      },
    ]);
  };
}
