import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const DEVEXPRESS_REPORT_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: 'devexpress-report-viewer',
        iconClass: 'fas fa-file-alt',
        name: 'report-viewer',
        layout: eLayoutType.application,
        requiredPolicy:"DevexpressReport.Viewer"
      },
    ]);
  };
}
