import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const BABIL_BANK_ACCOUNT_TRANSACTION_TO_VERAS_BANK_ACCOUNT_TRANSACTION_TO_VERA_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/kolay-ekstre/bank-account-transaction-to-veras',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:BankAccountTransactionToVeras',
        layout: eLayoutType.application,
        parentName:'::Menu:Babil',
        requiredPolicy: 'Babil.BankAccountTransactionToVeras',
      },
    ]);
  };
}
