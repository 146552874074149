import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const TEKNOSOL_COMPONENT_ISLETME_BAKIM_BAKIM_EMRI_PERSONEL_ROUTE_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true,
  },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/bakim-emri-personel-pivot-report',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:IBYS:Reports:IsEmriPersonelCalismaSureleri',
        layout: eLayoutType.application,
        requiredPolicy: 'Ibys.BakimEmriPersonelPivotReport',
        parentName: '::Menu:IBYS:Reports',
        order: 2,
      },
    ]);
  };
}
