import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const TEKNOSOL_COMPONENT_STOK_TIP_TEST_REPORT_ROUTE_PROVIDER = [
  {
    provide: APP_INITIALIZER,
    useFactory: configureRoutes,
    deps: [RoutesService],
    multi: true,
  },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/stok-tip-test',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:StokTipTest',
        layout: eLayoutType.application,
        requiredPolicy: 'AbpView.StokTips',
        parentName: '::Menu:IBYS:Test',
        order: 1,
      },
    ]);
  };
}
