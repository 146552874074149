import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const ISLETME_BAKIM_MAKINAS_MAKINA_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/makinas',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:Makinas',
        layout: eLayoutType.application,
        requiredPolicy: 'VeraIntegration.Makinas',
        parentName:'::Menu:Entegrasyon'
      },
    ]);
  };
}
