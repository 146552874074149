import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_LAB_SAMPLE_CATEGORIES_LAB_SAMPLE_CATEGORY_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/lab-sample-categories',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:LabSampleCategories',
        layout: eLayoutType.application,
        requiredPolicy: 'AbpView.LabSampleCategories',
       // requiredPolicy: 'Laboratory.LabSampleCategories',
       // parentName:"::Menu:SamplingProcedures",
        order:2
   
      },
    ]);
  };
}
