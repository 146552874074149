import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const LABORATORY_DEVICE_CALIBRATION_AND_LOG_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/device-calibration-and-log',
        iconClass: 'fas fa-file-alt',
        parentName:'::Menu:Laboratory',
        name: '::Laboratory:DeviceCalibrations',
        layout: eLayoutType.application,
        requiredPolicy: 'Laboratory.DeviceCalibrations',
      },
    ]);
  };
}
