import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_FM_EXTRA_DATAS_APP_FM_EXTRA_DATA_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/app-fm-extra-datas',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:AppFmExtraDatas',
        layout: eLayoutType.application,
        requiredPolicy: 'AbpView.AppFmExtraDatas',
      },
    ]);
  };
}
