import { eLayoutType, RoutesService } from '@abp/ng.core';
import { APP_INITIALIZER } from '@angular/core';

export const APP_FM_EXTRA_DATA_INFOS_APP_FM_EXTRA_DATA_INFO_ROUTE_PROVIDER = [
  { provide: APP_INITIALIZER, useFactory: configureRoutes, deps: [RoutesService], multi: true },
];

function configureRoutes(routes: RoutesService) {
  return () => {
    routes.add([
      {
        path: '/app-fm-extra-data-infos',
        iconClass: 'fas fa-file-alt',
        name: '::Menu:AppFmExtraDataInfos',
        layout: eLayoutType.application,
        requiredPolicy: 'AbpView.AppFmExtraDataInfos',
      },
    ]);
  };
}
